import { gql } from '@apollo/client';

const MARK_AS_DELETE = gql`
  mutation markOfferAsDeletedAndInactive($uuid: String!) {
    markOfferAsDeletedAndInactive(update: { uuid: $uuid }) {
      uuid
    }
  }
`;

export { MARK_AS_DELETE };
