import React from 'react';
import { Autocomplete, Box, makeStyles, Theme } from '@c2fo/react-components';
import { useQuery } from '@apollo/client';
import { TextField } from '..';
import { GET_TAGS } from '../../services/queries/tags';
import {
  ActionableOfferScheduleTagsCreateNew,
  ActionableOfferScheduleTagsQuery,
  ActionableOfferScheduleTagsQueryVariables,
} from '../../../generated-types/api-types-graphql';

const useStyles = makeStyles<Theme>((theme) => ({
  autoComplete: {
    marginTop: theme.spacing(2),
    minWidth: 300,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export interface TagsInputProps {
  values: Array<ActionableOfferScheduleTagsCreateNew>;
  setValue: (data: ActionableOfferScheduleTagsCreateNew[]) => void;
}

export const TagsInput = ({ values, setValue }: TagsInputProps) => {
  const classes = useStyles();

  const derivedValue = values.map((value) => value.name);

  const { data: response, loading } = useQuery<
    ActionableOfferScheduleTagsQuery,
    ActionableOfferScheduleTagsQueryVariables
  >(GET_TAGS, {
    fetchPolicy: 'cache-and-network',
  });

  const tags = response?.actionableOfferScheduleTags.edges.map((tag) => tag.node) ?? [];

  const convertToTags = (data: string[]) => {
    return data
      .map((item: string) => {
        return tags.find((tag) => tag.name === item) || { id: null, name: item };
      })
      .map(({ id, name }) => ({ id: id ? +id : null, name }));
  };

  return (
    <Autocomplete
      ListboxProps={{ 'data-testid': 'list-box-tags-input' }}
      loading={loading}
      value={derivedValue}
      className={classes.autoComplete}
      autoComplete
      multiple
      disableCloseOnSelect
      data-testid={'Autocomplete-tags-input'}
      id="tags"
      options={tags.map((option) => option.name)}
      freeSolo
      onChange={(_, text) => {
        setValue(convertToTags(text));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          name="tags"
          label="Tags"
          data-testid="Tags-TextField"
          placeholder="Press enter to add new tag"
          inputProps={{ maxLength: 24, ...params.inputProps }}
        />
      )}
    />
  );
};
