import { gql } from '@apollo/client';

const INITIATE_OFFER = gql`
  mutation initiateActionableOffer(
    $name: String!
    $srmHomeUserUuid: String!
    $authServiceUuid: String!
    $segmentId: String!
    $isDebugMode: Boolean!
    $tags: [Int!]
    $offerUuid: String!
    $currentDate: String!
  ) {
    initiateActionableOffer(
      input: {
        srmHomeUserUuid: $srmHomeUserUuid
        authServiceUuid: $authServiceUuid
        segmentId: $segmentId
        isDebugMode: $isDebugMode
        tags: $tags
        name: $name
        offerUuid: $offerUuid
        currentDate: $currentDate
      }
    ) {
      accountsCount
    }
  }
`;

export { INITIATE_OFFER };
