import { gql } from '@apollo/client';

export const GET_SEGMENTS_FOR_USER = gql`
  query getSegmentsForUser($authServiceUuid: String!) {
    getSegmentsForUser(authServiceUuid: $authServiceUuid) {
      id
      name
    }
  }
`;
