import { format } from 'date-fns';
import { CampaignType, RepetitionInterval, RepetitionStrategy } from '../../generated-types/api-types-graphql';
import { everyOptions, occurrence } from '../pages/SchedulerFormPage/formData';

interface ConfimationMessageprops {
  occurrence: string;
  startDate: string | number;
  executionTime: string | number;
  endDate: string | number | null;
  repetitionStrategy: string | null;
  repetitionInterval: string | null;
  executionDays: string[] | null;
  weekOfMonth: string[] | null;
}

const intervalMapping = new Map([
  [RepetitionInterval.EveryOne, '1'],
  [RepetitionInterval.EveryTwo, '2'],
  [RepetitionInterval.EveryThree, '3'],
  [RepetitionInterval.EveryFour, '4'],
  [RepetitionInterval.EveryFive, '5'],
]);

export function dateFormatter(date: string | number, dateFormat = 'yyyy-MM-dd') {
  if (!date) return '--';
  return format(new Date(date), dateFormat);
}
export function getCampaignCode(newParticipation: boolean, repeatParticipation: boolean, nonRegisteredUser: boolean) {
  const bitValues = [newParticipation, repeatParticipation, nonRegisteredUser];
  const code = bitValues.reduce((acc, bit, index) => {
    return acc + (bit ? 2 ** index : 0);
  }, 0);
  return parseInt(code.toString(2).padStart(4, '0'), 2);
}

export function decodeCampaignCode(code: number) {
  const bitArray = code
    .toString(2)
    .padStart(4, '0')
    .split('')
    .map((bit) => bit === '1');

  return {
    newParticipation: bitArray[3],
    repeatParticipation: bitArray[2],
    nonRegisteredUser: bitArray[1],
  };
}

export function getCampaignTypes(code: number) {
  const bitArray = code
    .toString(2)
    .padStart(4, '0')
    .split('')
    .map((bit) => bit === '1');
  const campaignTypes = [];
  if (bitArray[3]) campaignTypes.push(CampaignType.NewParticipation);
  if (bitArray[2]) campaignTypes.push(CampaignType.RepeatParticipation);
  if (bitArray[1]) campaignTypes.push(CampaignType.NonRegisteredUser);

  if (campaignTypes.length === 0) {
    campaignTypes.push(CampaignType.UnknownParticipation);
  }
  return campaignTypes;
}

export function snakeToCamel(str: string): string {
  return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
}
export const getDateFromTime = (time: number | string | Date) => {
  if (typeof time === 'number') {
    return time;
  }

  if (typeof time === 'string') {
    return new Date(`01/01/1970 ${time}`);
  }

  return time;
};

export const getConfimationMessage = (values: ConfimationMessageprops) => {
  if (values.occurrence === occurrence[1].value) {
    return `${dateFormatter(values.startDate, 'dd-MMM-yyyy')} at ${dateFormatter(values.executionTime, 'hh:mm a')}`;
  }

  const atAndTillString = `at ${dateFormatter(values.executionTime, 'hh:mm a')} until ${dateFormatter(
    values.endDate ?? Date.now(),
    'dd-MMM-yyyy',
  )}`;

  const intervalString = intervalMapping.get(values.repetitionInterval as RepetitionInterval);

  const { day, month } =
    values.repetitionInterval === everyOptions[0].value
      ? { day: 'day', month: 'month' }
      : { day: 'days', month: 'months' };

  const executionDaysValue = (values.executionDays ?? []).join(', ');
  const weekOfMonthValue = (values.weekOfMonth ?? []).join(', ');

  const messageCollection = new Map([
    [
      RepetitionStrategy.Daily,
      day === 'day'
        ? `Clicking on Ok will run this campaign in production everyday ${atAndTillString}`
        : `Clicking on Ok will run this campaign in production every ${intervalString} ${day} ${atAndTillString}`,
    ],
    [
      RepetitionStrategy.Weekly,
      `Clicking on Ok will schedule this campaign in production for every  ${executionDaysValue} ${atAndTillString}`,
    ],
    [
      RepetitionStrategy.Monthly,
      month === 'months'
        ? `Clicking on Ok will schedule this campaign for ${weekOfMonthValue} week on ${executionDaysValue} of every ${intervalString} ${month} ${atAndTillString}`
        : `Clicking on Ok will schedule this campaign for ${weekOfMonthValue} week on ${executionDaysValue} of every month  ${atAndTillString}`,
    ],
  ]);

  return messageCollection.get((values?.repetitionStrategy as RepetitionStrategy) ?? RepetitionStrategy.Daily);
};
