import { makeServices, ActiveDirectoryAuthService, HttpService, TokenService } from '@c2fo/react-services';
import { userAuthServiceConfig } from '@c2fo/common-config';
import { SegmentsService } from './segmentsService/segmentsService';
import { FileUploadService } from './file-upload/file-upload';

/**
 * Create service singletons.
 */
const tokenService = new TokenService('C2FOAuthToken');
const httpService = new HttpService(tokenService);
const authService = new ActiveDirectoryAuthService(
  tokenService,
  httpService,
  userAuthServiceConfig.USER_AUTH_SERVICE_URL,
);
const segmentsService = new SegmentsService(httpService);
const fileUploadService = new FileUploadService(httpService);

export interface Services {
  authService: ActiveDirectoryAuthService;
  tokenService: TokenService;
  httpService: HttpService;
  segmentsService: SegmentsService;
  fileUploadService: FileUploadService;
}

export const { ServicesProvider, ServicesContext, useServices } = makeServices<Services>({
  authService,
  tokenService,
  httpService,
  segmentsService,
  fileUploadService,
});
