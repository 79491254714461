/* eslint-disable max-statements */
import React, { useEffect, useState } from 'react';
import {
  AncillaryButton,
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  Modal,
  PencilIcon,
  PrimaryButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  TrashIcon,
  TypeBase,
  TypeHelper,
  TypeLabel,
  TypeSectionHeader,
  useSnackbar,
} from '@c2fo/react-components';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { GET_UPCOMING_CAMPAIGNS } from '../../../services/queries/upcomingCampaigns';
import { ActionableOfferSchedule, ScheduleTagsMappingEntity } from '../../../../generated-types/api-types-graphql';
import { ROUTES } from '../../../Router';
import { MARK_AS_DELETE } from '../../../services/mutations/markAsDelete';
import { dateFormatter, decodeCampaignCode, getCampaignTypes } from '../../../utils/utils';
import { UpcomingCampaignsTableHeader } from './UpcomingCampaignsTableHeader/UpcomingCampaignsTableHeader';
import { DEFAULT_SORT_COLUMN, SORT_DIRECTION } from '../../../constants';
import { PaginationDetailsProps } from '../CampaignsHistoryTable/CampaignHistoryTable';
import { CHECK_CRON_STATUS } from '../../../services/queries/checkCronStatus';

const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  updateAlert: {
    marginRight: theme.spacing(4),
  },
  chipText: {
    fontSize: 12,
    padding: 4,
    fontStyle: 'italic',
    textDecoration: 'underline',
    textOverflow: 'ellipsis',
    maxWidth: 200,
    overflow: 'hidden',
  },
}));

interface UpcomingCampaignsTableProps {
  isDebugMode: boolean;
}

export const PAGE_SIZE = 10;

export const UpcomingCampaignsTable: React.FC<UpcomingCampaignsTableProps> = ({
  isDebugMode,
}: UpcomingCampaignsTableProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { openSnackbar, closeSnackbar } = useSnackbar();
  const [isModalPromtOpen, setModalPromtOpen] = useState(false);
  const [sortParaams, setSortParams] = useState({
    field: DEFAULT_SORT_COLUMN.dbField,
    direction: SORT_DIRECTION.ASC,
    fieldId: DEFAULT_SORT_COLUMN.id,
  });

  const [{ pageNumber, pagination }, setPaginationDetails] = useState<PaginationDetailsProps>({
    pageNumber: 1,
    pagination: {
      after: '',
      first: PAGE_SIZE,
    },
  });

  const [{ deleteUUID, nameOfTheCampaign }, setDeleteUUID] = useState({
    deleteUUID: '',
    nameOfTheCampaign: '',
  });

  const [editUuid, setEditUuid] = useState({ editUuid: '' });
  const [srmHomeUuid, setSrmHomeUserUuid] = useState({ srmHomeUuid: '' });

  const [markOfferAsDeletedAndInactive, { data: deleteMutationData }] = useMutation(MARK_AS_DELETE);

  const { data: offerHistoryData, loading, refetch } = useQuery(GET_UPCOMING_CAMPAIGNS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true, // required to run onComplete in case of refetch
    variables: {
      paging: pagination,
      sorting: {
        field: sortParaams.field,
        direction: sortParaams.direction.toUpperCase(),
      },
    },
    onCompleted: () => {
      closeSnackbar('table-update-indicator-alert');
    },
  });

  const [getCronStatus, { loading: cronLoading }] = useLazyQuery(CHECK_CRON_STATUS, {
    onCompleted: (data) => {
      if (data.checkCronStatus) {
        history.push(
          `${ROUTES.EDIT_OFFER}/${srmHomeUuid.srmHomeUuid}/actionable-offer-id/${editUuid.editUuid}?isDebugMode=${isDebugMode}`,
        );
      }
    },
    onError: () => {
      openSnackbar(<Box>Please Wait 2-3 minutes before updating the AO</Box>, {
        variant: 'default',
        key: 'table-update-indicator-alert',
      });
    },
  });

  const pageInfo = offerHistoryData?.actionableOfferSchedules?.pageInfo ?? {};

  const offerHistoryTableData =
    offerHistoryData?.actionableOfferSchedules?.edges.map((item: { node: ActionableOfferSchedule }) => item.node) ?? [];

  useEffect(() => {
    if (deleteMutationData) {
      if (!pageInfo.hasNextPage && offerHistoryTableData.length === 1) {
        moveToPreviousPage();
      } else {
        refetch();
      }
      openSnackbar(
        <Box>
          <CircularProgress color="inherit" size={14} className={classes.updateAlert} /> Updating
        </Box>,
        { variant: 'default', key: 'table-update-indicator-alert' },
      );
    }
  }, [deleteMutationData]);

  if (loading && !offerHistoryTableData?.length) {
    return <div>Loading....</div>;
  }

  if (!offerHistoryTableData?.length) {
    return (
      <Box>
        <TypeSectionHeader align="center">No Upcoming Campaigns</TypeSectionHeader>
      </Box>
    );
  }

  const handleDeleteOffer = () => {
    markOfferAsDeletedAndInactive({ variables: { uuid: deleteUUID } });
    setModalPromtOpen(false);
    setDeleteUUID({
      deleteUUID: '',
      nameOfTheCampaign: '',
    });
  };

  const moveToNextPage = () => {
    setPaginationDetails((prev) => ({
      pageNumber: prev.pageNumber + 1,
      pagination: {
        after: pageInfo.endCursor,
        first: PAGE_SIZE,
      },
    }));
  };

  const moveToPreviousPage = () => {
    setPaginationDetails((prev) => ({
      pageNumber: prev.pageNumber - 1,
      pagination: {
        before: pageInfo.startCursor,
        last: PAGE_SIZE,
      },
    }));
  };

  const totalPages = Math.ceil(offerHistoryData?.actionableOfferSchedules.totalCount / PAGE_SIZE);

  return (
    <>
      <Modal
        closeButtonTestId="close-delete-modal"
        modalTitle="Confirmation"
        open={isModalPromtOpen}
        onClose={() => setModalPromtOpen(false)}
        actionsContent={<PrimaryButton onClick={handleDeleteOffer}>yes</PrimaryButton>}
      >
        <Box>
          <Box p={3}>
            <TypeSectionHeader align="center">
              Are you sure you want to delete {nameOfTheCampaign} campaign?
            </TypeSectionHeader>
          </Box>
        </Box>
      </Modal>
      <TableContainer className={classes.table}>
        <Table>
          <UpcomingCampaignsTableHeader
            sortCellId={sortParaams.fieldId}
            sortDirection={sortParaams.direction}
            handleSortSelection={setSortParams}
          />
          <TableBody>
            {offerHistoryTableData?.map((item: ActionableOfferSchedule) => {
              const {
                name,
                srmHomeUserUuid,
                campaignType,
                campaignCode,
                occurrence,
                uuid,
                owner,
                nextExecutionMilliseconds,
                srmHomeSegmentName,
                srmHomeSegmentId,
                tags,
                isDebugMode: debugMode,
              } = item;

              const tagList = tags?.map((tagItem: ScheduleTagsMappingEntity) => tagItem.tag) ?? [];

              return (
                <TableRow key={uuid}>
                  <TableCell>
                    <TypeBase>{name}</TypeBase>
                  </TableCell>
                  <TableCell>
                    {getCampaignTypes(campaignCode).map((target, index) => (
                      <TypeBase key={index}>{target}</TypeBase>
                    ))}
                  </TableCell>
                  <TableCell>
                    <TypeBase>{srmHomeSegmentName || 'N/A'}</TypeBase>
                  </TableCell>
                  <TableCell>
                    <TypeBase>{srmHomeUserUuid}</TypeBase>
                  </TableCell>
                  <TableCell>
                    <TypeBase>{occurrence}</TypeBase>
                  </TableCell>
                  <TableCell>
                    <TypeBase>{dateFormatter(nextExecutionMilliseconds ?? '', 'PPP , hh:mm:ss a')}</TypeBase>
                  </TableCell>
                  <TableCell>
                    <TypeBase>{owner}</TypeBase>
                  </TableCell>
                  <TableCell>
                    <TypeBase>{debugMode.toString()}</TypeBase>
                  </TableCell>
                  <TableCell>
                    {tagList.length > 0 ? (
                      tagList.map((tagItem) => (
                        <TypeHelper classes={{ root: classes.chipText }} key={tagItem.id}>
                          #{tagItem.name}
                        </TypeHelper>
                      ))
                    ) : (
                      <TypeBase>N/A</TypeBase>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={!srmHomeSegmentId || cronLoading}
                      data-testid={`${uuid}-edit`}
                      onClick={() => {
                        setEditUuid({ editUuid: uuid });
                        setSrmHomeUserUuid({ srmHomeUuid: srmHomeUserUuid });
                        getCronStatus({ variables: { uuid } });
                      }}
                    >
                      <PencilIcon fontSize="small" color="secondary" />
                    </IconButton>
                    <IconButton
                      data-testid={`${uuid}-delete`}
                      onClick={() => {
                        setDeleteUUID({ deleteUUID: uuid, nameOfTheCampaign: name });
                        setModalPromtOpen(true);
                      }}
                    >
                      <TrashIcon fontSize="small" color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box marginTop={2} className={classes.footer}>
          <AncillaryButton data-testid="button-back" disabled={!pageInfo.hasPreviousPage} onClick={moveToPreviousPage}>
            Back
          </AncillaryButton>
          <TypeLabel>
            Page {pageNumber} of {totalPages}
          </TypeLabel>
          <AncillaryButton data-testid="button-next" disabled={pageNumber === totalPages} onClick={moveToNextPage}>
            Next
          </AncillaryButton>
        </Box>
      </TableContainer>
    </>
  );
};
