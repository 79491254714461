import React, { useContext } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getAuthenticatedRoute } from '@c2fo/react-components';
import { ServicesContext } from './services';
import { BasePage, ScheduleActionableOfferPage, LoginPage, SchedulerFormPage, SegmentsPage } from './pages';

export enum ROUTES {
  HOME = '/',
  ACTIONABLE_OFFER = '/actionable-offer',
  SCHEDULE_OFFER = '/create-actionable-offer',
  EDIT_OFFER = '/edit-actionable-offer',
  RUN_OFFER = '/run-actionable-offer',
}

export const Router: React.FC = () => {
  const { authService } = useContext(ServicesContext);
  const AuthenticatedRoute = getAuthenticatedRoute({
    isAuthenticated: () => authService.isAuthenticated(),
    redirectTo: ROUTES.HOME,
  });

  return (
    <BrowserRouter>
      <BasePage>
        <Switch>
          <Route exact path={ROUTES.HOME} component={LoginPage} />
          <AuthenticatedRoute path={ROUTES.ACTIONABLE_OFFER} component={ScheduleActionableOfferPage} />
          <AuthenticatedRoute
            path={`${ROUTES.EDIT_OFFER}/:srmUserId/actionable-offer-id/:uuid`}
            component={SchedulerFormPage}
          />
          <AuthenticatedRoute path={`${ROUTES.SCHEDULE_OFFER}/:srmUserId`} component={SchedulerFormPage} />
          <AuthenticatedRoute path={`${ROUTES.RUN_OFFER}/:srmUserId`} component={SegmentsPage} />
          <Redirect to={ROUTES.HOME} />
        </Switch>
      </BasePage>
    </BrowserRouter>
  );
};
