import {
  ActionableOfferScheduleSortFields,
  ActionableOfferScheduleHistorySortFields,
} from '../../generated-types/api-types-graphql';

const {
  Name,
  CampaignType,
  Occurrence,
  NextExecutionMilliseconds,
  SrmHomeUserUuid,
} = ActionableOfferScheduleSortFields;

const { ExecutedOn } = ActionableOfferScheduleHistorySortFields;

const AO_TABLE_HEADERS_CELLS = [
  {
    id: 1,
    label: 'Name',
    dbField: Name,
    isSortEnabled: true,
  },
  {
    id: 2,
    label: 'Campaign type',
    dbField: CampaignType,
    isSortEnabled: true,
  },
  {
    id: 3,
    label: 'Segment Name',
    dbField: 'srmHomeSegmentName',
    isSortEnabled: false,
  },
  {
    id: 3,
    label: 'SRM home ID',
    dbField: SrmHomeUserUuid,
    isSortEnabled: true,
  },
  {
    id: 4,
    label: 'Occurrence',
    dbField: Occurrence,
    isSortEnabled: true,
  },
  {
    id: 5,
    label: 'Upcoming run Date & Time',
    dbField: NextExecutionMilliseconds,
    isSortEnabled: true,
  },
  {
    id: 6,
    label: 'Owner',
    dbField: 'owner',
    isSortEnabled: false,
  },
  {
    id: 7,
    label: 'Debug Mode',
    dbField: 'isDebugMode',
    isSortEnabled: false,
  },
  {
    id: 8,
    label: 'Tags',
    dbField: 'tags',
    isSortEnabled: false,
  },
  {
    id: 9,
    label: 'Action',
    dbField: 'action',
    isSortEnabled: false,
  },
];

// TODO: replace segmnt ID with segment name

const AO_HISTORY_TABLE_HEADERS_CELLS = [
  {
    id: 1,
    label: 'Name',
    dbField: Name,
    isSortEnabled: false,
  },
  {
    id: 2,
    label: 'Campaign type',
    dbField: CampaignType,
    isSortEnabled: false,
  },
  {
    id: 3,
    label: 'Segment Name',
    dbField: 'srmHomeSegmentName',
    isSortEnabled: false,
  },
  {
    id: 4,
    label: 'Occurrence',
    dbField: Occurrence,
    isSortEnabled: false,
  },
  {
    id: 5,
    label: 'Executed on',
    dbField: ExecutedOn,
    isSortEnabled: true,
  },
  {
    id: 6,
    label: 'Owner',
    dbField: 'owner',
    isSortEnabled: false,
  },
  {
    id: 7,
    label: 'Debug Mode',
    dbField: 'isDebugMode',
    isSortEnabled: false,
  },
  {
    id: 8,
    label: 'Status',
    dbField: 'status',
    isSortEnabled: false,
  },
  {
    id: 9,
    label: 'Action',
    dbField: 'action',
    isSortEnabled: false,
  },
];

enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

const DEFAULT_SORT_COLUMN = AO_TABLE_HEADERS_CELLS[5];

const DEFAULT_SORT_COLUMN_HISTORY = AO_HISTORY_TABLE_HEADERS_CELLS[4];

export {
  AO_TABLE_HEADERS_CELLS,
  DEFAULT_SORT_COLUMN,
  SORT_DIRECTION,
  AO_HISTORY_TABLE_HEADERS_CELLS,
  DEFAULT_SORT_COLUMN_HISTORY,
};
