import React from 'react';
import {
  Box,
  makeStyles,
  Theme,
  Modal,
  TypeLabel,
  TypeSubsectionHeader,
  Liquidity,
  CTAButton,
  TertiaryButton,
  CircularProgress,
} from '@c2fo/react-components';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3),
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableBody: {
    padding: spacing(3),
    margin: spacing(3, 7),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: spacing(0, 7),
  },
  cencelButton: {
    marginRight: 12,
  },
  icon: {
    marginRight: 8,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: spacing(4),
  },
  sectionMargin: {
    marginBottom: spacing(2),
  },
  progress: {
    marginLeft: spacing(1),
  },
}));

interface ConfirmationModalProps {
  modalOpen: boolean;
  setModalOpen(data: boolean): void;
  runOffer(): void;
  selectedSegmentName: string;
  isSchedule: boolean;
  loading?: boolean;
  message?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  modalOpen,
  setModalOpen,
  runOffer,
  selectedSegmentName,
  isSchedule,
  loading = false,
  message = '',
}: ConfirmationModalProps) => {
  const classes = useStyles();
  const queryParams = new URLSearchParams(useLocation().search);
  const isDebudMode = queryParams.get('isDebugMode') === 'true';
  const getDate = () => {
    return new Date().toString();
  };

  return (
    <Modal
      open={modalOpen}
      variant="informational"
      closeButtonTestId="modal-close-icon"
      actionsContent={
        <Box>
          <TertiaryButton className={classes.cencelButton} disabled={loading} onClick={() => setModalOpen(false)}>
            Cancel
          </TertiaryButton>
          <CTAButton data-testid="run-offer-modal-button" onClick={runOffer} disabled={loading}>
            {isSchedule ? 'Ok' : 'Run'}
            {loading && (
              <CircularProgress data-testid="progress-loader" className={classes.progress} size={18} color="inherit" />
            )}
          </CTAButton>
        </Box>
      }
      onClose={loading ? () => null : () => setModalOpen(false)}
    >
      <Box className={classes.modalContainer}>
        <TypeSubsectionHeader
          classes={{ root: classes.sectionMargin }}
          align="center"
          customColor={Liquidity.colors.supporting.deepBlue}
        >
          CONFIRMATION
        </TypeSubsectionHeader>
        {isDebudMode ? (
          <TypeLabel classes={{ root: classes.sectionMargin }} align="center">
            `You have selected debug mode. Clicking on {isSchedule ? 'Ok' : 'Run'} will not send any data to segment. Do
            you want to Proceed?`
          </TypeLabel>
        ) : (
          <TypeLabel classes={{ root: classes.sectionMargin }} align="center">
            {isSchedule
              ? `${message}. Do you want to proceed?`
              : 'Clicking on Run will trigger actionable offer campaign in the production environment. Do you want to Proceed?'}
          </TypeLabel>
        )}
        <TypeLabel align="center">{selectedSegmentName}</TypeLabel>
        {!isSchedule && (
          <TypeLabel classes={{ root: classes.sectionMargin }} data-testid="confirmation-date" align="center">
            {getDate()}
          </TypeLabel>
        )}
      </Box>
    </Modal>
  );
};
