export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AbandonedCartOfferDto = {
  __typename?: 'AbandonedCartOfferDto';
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  authServiceUuid: Scalars['String'];
  weightedAvgDpe: Scalars['String'];
  leadingMakerOrganizationUuid: Scalars['String'];
  leadingMarketingMakerName: Scalars['String'];
  marketingMakerNames: Array<Scalars['String']>;
  makerOrganizationUuids: Array<Scalars['String']>;
  eligibleInvoiceAmount: Scalars['String'];
  numberInvoices: Scalars['Float'];
  discountedAmount: Scalars['String'];
  discountAmount: Scalars['String'];
  goodFundsDate: Scalars['String'];
  suggestedOffer: Scalars['String'];
  suggestedOfferType: Scalars['String'];
  isExpressAcceptRate: Scalars['Boolean'];
  suggestedRateUuid: Scalars['String'];
  dsPricingUuid: Scalars['String'];
  draftOfferId: Scalars['String'];
  currency: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  srmName: Scalars['String'];
  srmEmail: Scalars['String'];
  numberOfCustomers: Scalars['Float'];
  firstName: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  offerDetailsQueryParam: Scalars['String'];
};

export type AbandonedCartOfferInput = {
  authServiceUuid: Scalars['String'];
  offerType: Scalars['String'];
  selectedRate?: Maybe<Scalars['Float']>;
  selectedRateType?: Maybe<Scalars['String']>;
  selectedPricingServiceUuid?: Maybe<Scalars['String']>;
  selectedCustomers?: Maybe<Array<SelectedCustomer>>;
  selectedOpportunities?: Maybe<Array<Scalars['String']>>;
};

export type AccountInput = {
  accountId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type AccountsCampaignInput = {
  name: Scalars['String'];
  accounts: Array<AccountInput>;
  srmHomeUserUuid?: Maybe<Scalars['String']>;
  isDebugMode?: Maybe<Scalars['Boolean']>;
  offerType?: Maybe<OfferType>;
};

export type ActionableOfferSchedule = {
  __typename?: 'ActionableOfferSchedule';
  uuid: Scalars['String'];
  name: Scalars['String'];
  srmHomeUserUuid: Scalars['String'];
  source: Source;
  srmHomeSegmentId?: Maybe<Scalars['String']>;
  campaignType: CampaignType;
  campaignCode: Scalars['Int'];
  occurrence: Occurrence;
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  executionTime: Scalars['String'];
  repetitionStrategy?: Maybe<RepetitionStrategy>;
  repetitionInterval?: Maybe<RepetitionInterval>;
  weekOfMonth?: Maybe<Array<WeekOfMonth>>;
  executionDays?: Maybe<Array<DayOfWeek>>;
  isDebugMode: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  owner: Scalars['String'];
  nextExecutionMilliseconds?: Maybe<Scalars['Float']>;
  srmHomeSegmentName: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  offerType: OfferType;
  tags?: Maybe<Array<ScheduleTagsMappingEntity>>;
};

export type ActionableOfferScheduleTagsArgs = {
  filter?: Maybe<ScheduleTagsMappingEntityFilter>;
  sorting?: Maybe<Array<ScheduleTagsMappingEntitySort>>;
};

export type ActionableOfferScheduleAggregateGroupBy = {
  __typename?: 'ActionableOfferScheduleAggregateGroupBy';
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  srmHomeUserUuid?: Maybe<Scalars['String']>;
  srmHomeSegmentId?: Maybe<Scalars['String']>;
  campaignType?: Maybe<CampaignType>;
  campaignCode?: Maybe<Scalars['Int']>;
  occurrence?: Maybe<Occurrence>;
  isActive?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  nextExecutionMilliseconds?: Maybe<Scalars['Float']>;
};

export type ActionableOfferScheduleAvgAggregate = {
  __typename?: 'ActionableOfferScheduleAvgAggregate';
  campaignCode?: Maybe<Scalars['Float']>;
  nextExecutionMilliseconds?: Maybe<Scalars['Float']>;
};

export type ActionableOfferScheduleConnection = {
  __typename?: 'ActionableOfferScheduleConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ActionableOfferScheduleEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionableOfferScheduleCountAggregate = {
  __typename?: 'ActionableOfferScheduleCountAggregate';
  uuid?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  srmHomeUserUuid?: Maybe<Scalars['Int']>;
  srmHomeSegmentId?: Maybe<Scalars['Int']>;
  campaignType?: Maybe<Scalars['Int']>;
  campaignCode?: Maybe<Scalars['Int']>;
  occurrence?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
  nextExecutionMilliseconds?: Maybe<Scalars['Int']>;
};

export type ActionableOfferScheduleDeleteResponse = {
  __typename?: 'ActionableOfferScheduleDeleteResponse';
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  srmHomeUserUuid?: Maybe<Scalars['String']>;
  source?: Maybe<Source>;
  srmHomeSegmentId?: Maybe<Scalars['String']>;
  campaignType?: Maybe<CampaignType>;
  campaignCode?: Maybe<Scalars['Int']>;
  occurrence?: Maybe<Occurrence>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  executionTime?: Maybe<Scalars['String']>;
  repetitionStrategy?: Maybe<RepetitionStrategy>;
  repetitionInterval?: Maybe<RepetitionInterval>;
  weekOfMonth?: Maybe<Array<WeekOfMonth>>;
  executionDays?: Maybe<Array<DayOfWeek>>;
  isDebugMode?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<Scalars['String']>;
  nextExecutionMilliseconds?: Maybe<Scalars['Float']>;
  srmHomeSegmentName?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  offerType?: Maybe<OfferType>;
};

export type ActionableOfferScheduleEdge = {
  __typename?: 'ActionableOfferScheduleEdge';
  /** The node containing the ActionableOfferSchedule */
  node: ActionableOfferSchedule;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ActionableOfferScheduleFileInput = {
  uuid: Scalars['String'];
};

export type ActionableOfferScheduleFilter = {
  and?: Maybe<Array<ActionableOfferScheduleFilter>>;
  or?: Maybe<Array<ActionableOfferScheduleFilter>>;
  uuid?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  srmHomeUserUuid?: Maybe<StringFieldComparison>;
  srmHomeSegmentId?: Maybe<StringFieldComparison>;
  campaignType?: Maybe<CampaignTypeFilterComparison>;
  campaignCode?: Maybe<IntFieldComparison>;
  occurrence?: Maybe<OccurrenceFilterComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
  created?: Maybe<DateFieldComparison>;
  updated?: Maybe<DateFieldComparison>;
  nextExecutionMilliseconds?: Maybe<NumberFieldComparison>;
};

export type ActionableOfferScheduleHistory = {
  __typename?: 'ActionableOfferScheduleHistory';
  uuid: Scalars['String'];
  offerUuid: Scalars['String'];
  executedOn: Scalars['String'];
  updatedDate?: Maybe<Scalars['DateTime']>;
  status: Status;
  offer: ActionableOfferSchedule;
};

export type ActionableOfferScheduleHistoryAggregateGroupBy = {
  __typename?: 'ActionableOfferScheduleHistoryAggregateGroupBy';
  uuid?: Maybe<Scalars['String']>;
  executedOn?: Maybe<Scalars['String']>;
};

export type ActionableOfferScheduleHistoryConnection = {
  __typename?: 'ActionableOfferScheduleHistoryConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ActionableOfferScheduleHistoryEdge>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ActionableOfferScheduleHistoryCountAggregate = {
  __typename?: 'ActionableOfferScheduleHistoryCountAggregate';
  uuid?: Maybe<Scalars['Int']>;
  executedOn?: Maybe<Scalars['Int']>;
};

export type ActionableOfferScheduleHistoryEdge = {
  __typename?: 'ActionableOfferScheduleHistoryEdge';
  /** The node containing the ActionableOfferScheduleHistory */
  node: ActionableOfferScheduleHistory;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ActionableOfferScheduleHistoryFilter = {
  and?: Maybe<Array<ActionableOfferScheduleHistoryFilter>>;
  or?: Maybe<Array<ActionableOfferScheduleHistoryFilter>>;
  uuid?: Maybe<StringFieldComparison>;
  executedOn?: Maybe<StringFieldComparison>;
};

export type ActionableOfferScheduleHistoryMaxAggregate = {
  __typename?: 'ActionableOfferScheduleHistoryMaxAggregate';
  uuid?: Maybe<Scalars['String']>;
  executedOn?: Maybe<Scalars['String']>;
};

export type ActionableOfferScheduleHistoryMinAggregate = {
  __typename?: 'ActionableOfferScheduleHistoryMinAggregate';
  uuid?: Maybe<Scalars['String']>;
  executedOn?: Maybe<Scalars['String']>;
};

export type ActionableOfferScheduleHistorySort = {
  field: ActionableOfferScheduleHistorySortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ActionableOfferScheduleHistorySortFields {
  Uuid = 'uuid',
  ExecutedOn = 'executedOn',
}

export type ActionableOfferScheduleInput = {
  name: Scalars['String'];
  srmHomeUserUuid: Scalars['String'];
  source?: Maybe<Source>;
  file?: Maybe<ActionableOfferScheduleFileInput>;
  srmHomeSegmentId?: Maybe<Scalars['String']>;
  campaignType: CampaignType;
  campaignCode: Scalars['Float'];
  occurrence: Occurrence;
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  executionTime: Scalars['String'];
  repetitionStrategy?: Maybe<RepetitionStrategy>;
  repetitionInterval?: Maybe<RepetitionInterval>;
  weekOfMonth?: Maybe<Array<WeekOfMonth>>;
  executionDays?: Maybe<Array<DayOfWeek>>;
  isDebugMode?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<ActionableOfferScheduleTagsCreateNew>>;
  offerType?: Maybe<OfferType>;
};

export type ActionableOfferScheduleMaxAggregate = {
  __typename?: 'ActionableOfferScheduleMaxAggregate';
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  srmHomeUserUuid?: Maybe<Scalars['String']>;
  srmHomeSegmentId?: Maybe<Scalars['String']>;
  campaignType?: Maybe<CampaignType>;
  campaignCode?: Maybe<Scalars['Int']>;
  occurrence?: Maybe<Occurrence>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  nextExecutionMilliseconds?: Maybe<Scalars['Float']>;
};

export type ActionableOfferScheduleMinAggregate = {
  __typename?: 'ActionableOfferScheduleMinAggregate';
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  srmHomeUserUuid?: Maybe<Scalars['String']>;
  srmHomeSegmentId?: Maybe<Scalars['String']>;
  campaignType?: Maybe<CampaignType>;
  campaignCode?: Maybe<Scalars['Int']>;
  occurrence?: Maybe<Occurrence>;
  created?: Maybe<Scalars['DateTime']>;
  updated?: Maybe<Scalars['DateTime']>;
  nextExecutionMilliseconds?: Maybe<Scalars['Float']>;
};

export type ActionableOfferScheduleSort = {
  field: ActionableOfferScheduleSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ActionableOfferScheduleSortFields {
  Uuid = 'uuid',
  Name = 'name',
  SrmHomeUserUuid = 'srmHomeUserUuid',
  SrmHomeSegmentId = 'srmHomeSegmentId',
  CampaignType = 'campaignType',
  CampaignCode = 'campaignCode',
  Occurrence = 'occurrence',
  IsActive = 'isActive',
  Created = 'created',
  Updated = 'updated',
  NextExecutionMilliseconds = 'nextExecutionMilliseconds',
}

export type ActionableOfferScheduleSumAggregate = {
  __typename?: 'ActionableOfferScheduleSumAggregate';
  campaignCode?: Maybe<Scalars['Float']>;
  nextExecutionMilliseconds?: Maybe<Scalars['Float']>;
};

export type ActionableOfferScheduleTags = {
  __typename?: 'ActionableOfferScheduleTags';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ActionableOfferScheduleTagsAggregateGroupBy = {
  __typename?: 'ActionableOfferScheduleTagsAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type ActionableOfferScheduleTagsAvgAggregate = {
  __typename?: 'ActionableOfferScheduleTagsAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ActionableOfferScheduleTagsConnection = {
  __typename?: 'ActionableOfferScheduleTagsConnection';
  /** Paging information */
  pageInfo: PageInfo;
  /** Array of edges. */
  edges: Array<ActionableOfferScheduleTagsEdge>;
};

export type ActionableOfferScheduleTagsCountAggregate = {
  __typename?: 'ActionableOfferScheduleTagsCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type ActionableOfferScheduleTagsCreateNew = {
  id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type ActionableOfferScheduleTagsEdge = {
  __typename?: 'ActionableOfferScheduleTagsEdge';
  /** The node containing the ActionableOfferScheduleTags */
  node: ActionableOfferScheduleTags;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ActionableOfferScheduleTagsFilter = {
  and?: Maybe<Array<ActionableOfferScheduleTagsFilter>>;
  or?: Maybe<Array<ActionableOfferScheduleTagsFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type ActionableOfferScheduleTagsMaxAggregate = {
  __typename?: 'ActionableOfferScheduleTagsMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ActionableOfferScheduleTagsMinAggregate = {
  __typename?: 'ActionableOfferScheduleTagsMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type ActionableOfferScheduleTagsSort = {
  field: ActionableOfferScheduleTagsSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ActionableOfferScheduleTagsSortFields {
  Id = 'id',
}

export type ActionableOfferScheduleTagsSumAggregate = {
  __typename?: 'ActionableOfferScheduleTagsSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ActionableOfferScheduleUpdate = {
  name?: Maybe<Scalars['String']>;
  srmHomeUserUuid?: Maybe<Scalars['String']>;
  source?: Maybe<Source>;
  file?: Maybe<ActionableOfferScheduleFileInput>;
  srmHomeSegmentId?: Maybe<Scalars['String']>;
  campaignType?: Maybe<CampaignType>;
  campaignCode?: Maybe<Scalars['Float']>;
  occurrence?: Maybe<Occurrence>;
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  executionTime: Scalars['String'];
  repetitionStrategy?: Maybe<RepetitionStrategy>;
  repetitionInterval?: Maybe<RepetitionInterval>;
  weekOfMonth?: Maybe<Array<WeekOfMonth>>;
  executionDays?: Maybe<Array<DayOfWeek>>;
  isDebugMode?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<Scalars['String']>;
  nextExecutionMilliseconds?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<ActionableOfferScheduleTagsCreateNew>>;
  offerType?: Maybe<OfferType>;
};

export type BooleanFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
};

export type CampaignInput = {
  name: Scalars['String'];
  srmHomeUserUuid: Scalars['String'];
  srmHomeSegmentId: Scalars['String'];
  isDebugMode?: Maybe<Scalars['Boolean']>;
};

export enum CampaignType {
  UnknownParticipation = 'UNKNOWN_PARTICIPATION',
  NewParticipation = 'NEW_PARTICIPATION',
  RepeatParticipation = 'REPEAT_PARTICIPATION',
  NonRegisteredUser = 'NON_REGISTERED_USER',
}

export type CampaignTypeFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<CampaignType>;
  neq?: Maybe<CampaignType>;
  gt?: Maybe<CampaignType>;
  gte?: Maybe<CampaignType>;
  lt?: Maybe<CampaignType>;
  lte?: Maybe<CampaignType>;
  like?: Maybe<CampaignType>;
  notLike?: Maybe<CampaignType>;
  iLike?: Maybe<CampaignType>;
  notILike?: Maybe<CampaignType>;
  in?: Maybe<Array<CampaignType>>;
  notIn?: Maybe<Array<CampaignType>>;
};

export type CreateOneActionableOfferScheduleInput = {
  /** The record to create */
  actionableOfferSchedule: ActionableOfferScheduleInput;
};

export type CursorPaging = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: Maybe<Scalars['Int']>;
  /** Paginate last */
  last?: Maybe<Scalars['Int']>;
};

export type DateFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export enum DayOfWeek {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
}

export type DeleteOneActionableOfferScheduleInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type FileCampaignInput = {
  name: Scalars['String'];
  file: ActionableOfferScheduleFileInput;
  srmHomeUserUuid?: Maybe<Scalars['String']>;
  isDebugMode?: Maybe<Scalars['Boolean']>;
  offerType?: Maybe<OfferType>;
};

export type GenericResponseDto = {
  __typename?: 'GenericResponseDto';
  status: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type IdFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['ID']>;
  neq?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  like?: Maybe<Scalars['ID']>;
  notLike?: Maybe<Scalars['ID']>;
  iLike?: Maybe<Scalars['ID']>;
  notILike?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  notIn?: Maybe<Array<Scalars['ID']>>;
};

export type InitiateActionableOfferDto = {
  __typename?: 'InitiateActionableOfferDto';
  accountsCount: Scalars['Int'];
};

export type InitiateActionableOfferInput = {
  name: Scalars['String'];
  authServiceUuid: Scalars['String'];
  offerUuid: Scalars['String'];
  currentDate: Scalars['String'];
  srmHomeUserUuid?: Maybe<Scalars['String']>;
  segmentId: Scalars['String'];
  isDebugMode: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['Int']>>;
  offerType?: Maybe<OfferType>;
  occurrenceType?: Maybe<Occurrence>;
};

export type IntFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  between?: Maybe<IntFieldComparisonBetween>;
  notBetween?: Maybe<IntFieldComparisonBetween>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int'];
  upper: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  removeOfferFromActionableOfferScheduleHistory: ActionableOfferScheduleHistory;
  setOfferOnActionableOfferScheduleHistory: ActionableOfferScheduleHistory;
  removeTagFromScheduleTagsMappingEntity: ScheduleTagsMappingEntity;
  setTagOnScheduleTagsMappingEntity: ScheduleTagsMappingEntity;
  createAbandonedCartOffer: AbandonedCartOfferDto;
  initiateActionableOffer: InitiateActionableOfferDto;
  runCampaignViaFile: GenericResponseDto;
  runCampaignForAccounts: GenericResponseDto;
  markOfferAsDeletedAndInactive: ScheduleMarkAsDelete;
  runCampaign: InitiateActionableOfferDto;
  markStopStatusOfOngoingCampaign: ScheduleMarkAsStop;
  deleteOneActionableOfferSchedule: ActionableOfferScheduleDeleteResponse;
  updateOneActionableOfferSchedule: ActionableOfferSchedule;
  createOneActionableOfferSchedule: ActionableOfferSchedule;
  removeTagsFromActionableOfferSchedule: ActionableOfferSchedule;
};

export type MutationRemoveOfferFromActionableOfferScheduleHistoryArgs = {
  input: RemoveOfferFromActionableOfferScheduleHistoryInput;
};

export type MutationSetOfferOnActionableOfferScheduleHistoryArgs = {
  input: SetOfferOnActionableOfferScheduleHistoryInput;
};

export type MutationRemoveTagFromScheduleTagsMappingEntityArgs = {
  input: RemoveTagFromScheduleTagsMappingEntityInput;
};

export type MutationSetTagOnScheduleTagsMappingEntityArgs = {
  input: SetTagOnScheduleTagsMappingEntityInput;
};

export type MutationCreateAbandonedCartOfferArgs = {
  input: AbandonedCartOfferInput;
};

export type MutationInitiateActionableOfferArgs = {
  input: InitiateActionableOfferInput;
};

export type MutationRunCampaignViaFileArgs = {
  campaign: FileCampaignInput;
};

export type MutationRunCampaignForAccountsArgs = {
  campaign: AccountsCampaignInput;
};

export type MutationMarkOfferAsDeletedAndInactiveArgs = {
  update: ScheduleMarkAsDeleteInput;
};

export type MutationRunCampaignArgs = {
  campaign: CampaignInput;
};

export type MutationMarkStopStatusOfOngoingCampaignArgs = {
  update: ScheduleMarkAsStopInput;
};

export type MutationDeleteOneActionableOfferScheduleArgs = {
  input: DeleteOneActionableOfferScheduleInput;
};

export type MutationUpdateOneActionableOfferScheduleArgs = {
  input: UpdateOneActionableOfferScheduleInput;
};

export type MutationCreateOneActionableOfferScheduleArgs = {
  input: CreateOneActionableOfferScheduleInput;
};

export type MutationRemoveTagsFromActionableOfferScheduleArgs = {
  input: RemoveTagsFromActionableOfferScheduleInput;
};

export type NumberFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  between?: Maybe<NumberFieldComparisonBetween>;
  notBetween?: Maybe<NumberFieldComparisonBetween>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export enum Occurrence {
  Instant = 'INSTANT',
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING',
}

export type OccurrenceFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Occurrence>;
  neq?: Maybe<Occurrence>;
  gt?: Maybe<Occurrence>;
  gte?: Maybe<Occurrence>;
  lt?: Maybe<Occurrence>;
  lte?: Maybe<Occurrence>;
  like?: Maybe<Occurrence>;
  notLike?: Maybe<Occurrence>;
  iLike?: Maybe<Occurrence>;
  notILike?: Maybe<Occurrence>;
  in?: Maybe<Array<Occurrence>>;
  notIn?: Maybe<Array<Occurrence>>;
};

export enum OfferType {
  OneTime = 'ONE_TIME',
  Repeating = 'REPEATING',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export type Query = {
  __typename?: 'Query';
  actionableOfferScheduleHistories: ActionableOfferScheduleHistoryConnection;
  actionableOfferScheduleTags: ActionableOfferScheduleTagsConnection;
  getSegmentsForUser: Array<SegmentsDto>;
  checkCronStatus: Scalars['Boolean'];
  actionableOfferSchedule?: Maybe<ActionableOfferSchedule>;
  actionableOfferSchedules: ActionableOfferScheduleConnection;
};

export type QueryActionableOfferScheduleHistoriesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ActionableOfferScheduleHistoryFilter>;
  sorting?: Maybe<Array<ActionableOfferScheduleHistorySort>>;
};

export type QueryActionableOfferScheduleTagsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ActionableOfferScheduleTagsFilter>;
  sorting?: Maybe<Array<ActionableOfferScheduleTagsSort>>;
};

export type QueryGetSegmentsForUserArgs = {
  authServiceUuid: Scalars['String'];
};

export type QueryCheckCronStatusArgs = {
  scheduleUuid: Scalars['String'];
};

export type QueryActionableOfferScheduleArgs = {
  id: Scalars['ID'];
};

export type QueryActionableOfferSchedulesArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ActionableOfferScheduleFilter>;
  sorting?: Maybe<Array<ActionableOfferScheduleSort>>;
};

export type RemoveOfferFromActionableOfferScheduleHistoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveTagFromScheduleTagsMappingEntityInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveTagsFromActionableOfferScheduleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export enum RepetitionInterval {
  EveryOne = 'EVERY_ONE',
  EveryTwo = 'EVERY_TWO',
  EveryThree = 'EVERY_THREE',
  EveryFour = 'EVERY_FOUR',
  EveryFive = 'EVERY_FIVE',
}

export enum RepetitionStrategy {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

export type ScheduleMarkAsDelete = {
  __typename?: 'ScheduleMarkAsDelete';
  uuid: Scalars['String'];
};

export type ScheduleMarkAsDeleteInput = {
  uuid: Scalars['String'];
};

export type ScheduleMarkAsStop = {
  __typename?: 'ScheduleMarkAsStop';
  uuid: Scalars['String'];
};

export type ScheduleMarkAsStopInput = {
  uuid: Scalars['String'];
};

export type ScheduleTagsMappingEntity = {
  __typename?: 'ScheduleTagsMappingEntity';
  scheduleTagId: Scalars['ID'];
  offerUuid: Scalars['String'];
  tagId: Scalars['Float'];
  tag: ActionableOfferScheduleTags;
};

export type ScheduleTagsMappingEntityAggregateGroupBy = {
  __typename?: 'ScheduleTagsMappingEntityAggregateGroupBy';
  scheduleTagId?: Maybe<Scalars['ID']>;
};

export type ScheduleTagsMappingEntityCountAggregate = {
  __typename?: 'ScheduleTagsMappingEntityCountAggregate';
  scheduleTagId?: Maybe<Scalars['Int']>;
};

export type ScheduleTagsMappingEntityEdge = {
  __typename?: 'ScheduleTagsMappingEntityEdge';
  /** The node containing the ScheduleTagsMappingEntity */
  node: ScheduleTagsMappingEntity;
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
};

export type ScheduleTagsMappingEntityFilter = {
  and?: Maybe<Array<ScheduleTagsMappingEntityFilter>>;
  or?: Maybe<Array<ScheduleTagsMappingEntityFilter>>;
  scheduleTagId?: Maybe<IdFilterComparison>;
};

export type ScheduleTagsMappingEntityMaxAggregate = {
  __typename?: 'ScheduleTagsMappingEntityMaxAggregate';
  scheduleTagId?: Maybe<Scalars['ID']>;
};

export type ScheduleTagsMappingEntityMinAggregate = {
  __typename?: 'ScheduleTagsMappingEntityMinAggregate';
  scheduleTagId?: Maybe<Scalars['ID']>;
};

export type ScheduleTagsMappingEntitySort = {
  field: ScheduleTagsMappingEntitySortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ScheduleTagsMappingEntitySortFields {
  ScheduleTagId = 'scheduleTagId',
}

export type SegmentsDto = {
  __typename?: 'SegmentsDto';
  id: Scalars['String'];
  name: Scalars['String'];
};

/**
 * SelectedCustomer repersent customer(s) that you want to filter opportunities by.
 * This repersents the customers selected by the user when they were building an offer before it was abandoned
 */
export type SelectedCustomer = {
  makerOrganizationUuid: Scalars['String'];
  currency: Scalars['String'];
};

export type SetOfferOnActionableOfferScheduleHistoryInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetTagOnScheduleTagsMappingEntityInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST',
}

export enum Source {
  Segment = 'SEGMENT',
  Csv = 'CSV',
  List = 'LIST',
}

export enum Status {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  InProgress = 'IN_PROGRESS',
  Stopped = 'STOPPED',
}

export type StringFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  like?: Maybe<Scalars['String']>;
  notLike?: Maybe<Scalars['String']>;
  iLike?: Maybe<Scalars['String']>;
  notILike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
};

export type UpdateOneActionableOfferScheduleInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: ActionableOfferScheduleUpdate;
};

export enum WeekOfMonth {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
  Fourth = 'FOURTH',
  Last = 'LAST',
}

export type CreateOneActionableOfferScheduleMutationVariables = Exact<{
  name: Scalars['String'];
  srmHomeUserUuid: Scalars['String'];
  srmHomeSegmentId?: Maybe<Scalars['String']>;
  campaignType: CampaignType;
  campaignCode: Scalars['Float'];
  occurrence: Occurrence;
  offerType: OfferType;
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  executionTime: Scalars['String'];
  repetitionStrategy?: Maybe<RepetitionStrategy>;
  repetitionInterval?: Maybe<RepetitionInterval>;
  executionDays?: Maybe<Array<DayOfWeek>>;
  isDebugMode?: Maybe<Scalars['Boolean']>;
  weekOfMonth?: Maybe<Array<WeekOfMonth>>;
  tags?: Maybe<Array<ActionableOfferScheduleTagsCreateNew>>;
  source?: Maybe<Source>;
  file?: Maybe<ActionableOfferScheduleFileInput>;
}>;

export type CreateOneActionableOfferScheduleMutation = { __typename?: 'Mutation' } & {
  createOneActionableOfferSchedule: { __typename?: 'ActionableOfferSchedule' } & Pick<ActionableOfferSchedule, 'uuid'>;
};

export type InitiateActionableOfferMutationVariables = Exact<{
  name: Scalars['String'];
  srmHomeUserUuid: Scalars['String'];
  authServiceUuid: Scalars['String'];
  segmentId: Scalars['String'];
  isDebugMode: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['Int']>>;
  offerUuid: Scalars['String'];
  currentDate: Scalars['String'];
}>;

export type InitiateActionableOfferMutation = { __typename?: 'Mutation' } & {
  initiateActionableOffer: { __typename?: 'InitiateActionableOfferDto' } & Pick<
    InitiateActionableOfferDto,
    'accountsCount'
  >;
};

export type RunCampaignViaFileMutationVariables = Exact<{
  name: Scalars['String'];
  file: ActionableOfferScheduleFileInput;
  srmHomeUserUuid: Scalars['String'];
  isDebugMode: Scalars['Boolean'];
}>;

export type RunCampaignViaFileMutation = { __typename?: 'Mutation' } & {
  runCampaignViaFile: { __typename?: 'GenericResponseDto' } & Pick<GenericResponseDto, 'status'>;
};

export type MarkOfferAsDeletedAndInactiveMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type MarkOfferAsDeletedAndInactiveMutation = { __typename?: 'Mutation' } & {
  markOfferAsDeletedAndInactive: { __typename?: 'ScheduleMarkAsDelete' } & Pick<ScheduleMarkAsDelete, 'uuid'>;
};

export type UpdateOneActionableOfferScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  srmHomeUserUuid: Scalars['String'];
  srmHomeSegmentId: Scalars['String'];
  campaignType: CampaignType;
  campaignCode: Scalars['Float'];
  occurrence: Occurrence;
  offerType: OfferType;
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  executionTime: Scalars['String'];
  repetitionStrategy?: Maybe<RepetitionStrategy>;
  repetitionInterval?: Maybe<RepetitionInterval>;
  executionDays?: Maybe<Array<DayOfWeek>>;
  isDebugMode?: Maybe<Scalars['Boolean']>;
  weekOfMonth?: Maybe<Array<WeekOfMonth>>;
  tags?: Maybe<Array<ActionableOfferScheduleTagsCreateNew>>;
}>;

export type UpdateOneActionableOfferScheduleMutation = { __typename?: 'Mutation' } & {
  updateOneActionableOfferSchedule: { __typename?: 'ActionableOfferSchedule' } & Pick<ActionableOfferSchedule, 'uuid'>;
};

export type CheckCronStatusQueryVariables = Exact<{
  scheduleUuid: Scalars['String'];
}>;

export type CheckCronStatusQuery = { __typename?: 'Query' } & Pick<Query, 'checkCronStatus'>;

export type GetSegmentsForUserQueryVariables = Exact<{
  authServiceUuid: Scalars['String'];
}>;

export type GetSegmentsForUserQuery = { __typename?: 'Query' } & {
  getSegmentsForUser: Array<{ __typename?: 'SegmentsDto' } & Pick<SegmentsDto, 'id' | 'name'>>;
};

export type ActionableOfferScheduleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ActionableOfferScheduleQuery = { __typename?: 'Query' } & {
  actionableOfferSchedule?: Maybe<
    { __typename?: 'ActionableOfferSchedule' } & Pick<
      ActionableOfferSchedule,
      | 'name'
      | 'campaignType'
      | 'campaignCode'
      | 'uuid'
      | 'srmHomeUserUuid'
      | 'srmHomeSegmentId'
      | 'occurrence'
      | 'startDate'
      | 'endDate'
      | 'timeZone'
      | 'offerType'
      | 'executionTime'
      | 'repetitionStrategy'
      | 'repetitionInterval'
      | 'executionDays'
      | 'weekOfMonth'
    > & {
        tags?: Maybe<
          Array<
            { __typename?: 'ScheduleTagsMappingEntity' } & {
              tag: { __typename?: 'ActionableOfferScheduleTags' } & Pick<ActionableOfferScheduleTags, 'name' | 'id'>;
            }
          >
        >;
      }
  >;
};

export type ActionableOfferScheduleHistoriesQueryVariables = Exact<{
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<ActionableOfferScheduleHistorySort>>;
  filter?: Maybe<ActionableOfferScheduleHistoryFilter>;
}>;

export type ActionableOfferScheduleHistoriesQuery = { __typename?: 'Query' } & {
  actionableOfferScheduleHistories: { __typename?: 'ActionableOfferScheduleHistoryConnection' } & Pick<
    ActionableOfferScheduleHistoryConnection,
    'totalCount'
  > & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
      edges: Array<
        { __typename?: 'ActionableOfferScheduleHistoryEdge' } & {
          node: { __typename?: 'ActionableOfferScheduleHistory' } & Pick<
            ActionableOfferScheduleHistory,
            'uuid' | 'status' | 'executedOn'
          > & {
              offer: { __typename?: 'ActionableOfferSchedule' } & Pick<
                ActionableOfferSchedule,
                | 'name'
                | 'campaignType'
                | 'campaignCode'
                | 'occurrence'
                | 'owner'
                | 'srmHomeSegmentId'
                | 'uuid'
                | 'srmHomeSegmentName'
                | 'isDebugMode'
                | 'timeZone'
              >;
            };
        }
      >;
    };
};

export type MarkStopStatusOfOngoingCampaignMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type MarkStopStatusOfOngoingCampaignMutation = { __typename?: 'Mutation' } & {
  markStopStatusOfOngoingCampaign: { __typename?: 'ScheduleMarkAsStop' } & Pick<ScheduleMarkAsStop, 'uuid'>;
};

export type ActionableOfferScheduleTagsQueryVariables = Exact<{
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<ActionableOfferScheduleTagsSort>>;
}>;

export type ActionableOfferScheduleTagsQuery = { __typename?: 'Query' } & {
  actionableOfferScheduleTags: { __typename?: 'ActionableOfferScheduleTagsConnection' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<
      PageInfo,
      'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
    >;
    edges: Array<
      { __typename?: 'ActionableOfferScheduleTagsEdge' } & {
        node: { __typename?: 'ActionableOfferScheduleTags' } & Pick<ActionableOfferScheduleTags, 'id' | 'name'>;
      }
    >;
  };
};

export type ActionableOfferSchedulesQueryVariables = Exact<{
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<ActionableOfferScheduleSort>>;
}>;

export type ActionableOfferSchedulesQuery = { __typename?: 'Query' } & {
  actionableOfferSchedules: { __typename?: 'ActionableOfferScheduleConnection' } & Pick<
    ActionableOfferScheduleConnection,
    'totalCount'
  > & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
      edges: Array<
        { __typename?: 'ActionableOfferScheduleEdge' } & {
          node: { __typename?: 'ActionableOfferSchedule' } & Pick<
            ActionableOfferSchedule,
            | 'name'
            | 'campaignType'
            | 'campaignCode'
            | 'uuid'
            | 'srmHomeUserUuid'
            | 'occurrence'
            | 'startDate'
            | 'endDate'
            | 'timeZone'
            | 'executionTime'
            | 'repetitionStrategy'
            | 'repetitionInterval'
            | 'executionDays'
            | 'owner'
            | 'isDebugMode'
            | 'nextExecutionMilliseconds'
            | 'srmHomeSegmentName'
            | 'srmHomeSegmentId'
          > & {
              tags?: Maybe<
                Array<
                  { __typename?: 'ScheduleTagsMappingEntity' } & {
                    tag: { __typename?: 'ActionableOfferScheduleTags' } & Pick<
                      ActionableOfferScheduleTags,
                      'name' | 'id'
                    >;
                  }
                >
              >;
            };
        }
      >;
    };
};
