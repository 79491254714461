import { HttpService } from '@c2fo/react-services';
import { config } from '../../../config';

export class FileUploadService {
  constructor(private httpService: HttpService) {}

  async upload(data: FormData) {
    try {
      return await this.httpService.post(`${config.AO_API_URL}/file/upload`, {
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      throw new Error('Unable to upload csv');
    }
  }
}
