import React from 'react';
import fetch from 'cross-fetch';
import {
  from,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  NormalizedCacheObject,
  ApolloLink,
  DefaultOptions,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { TokenService } from '@c2fo/react-services';
import { config } from '../../config';

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'network-only',
  },
};

const getHttpLink = (url: string) =>
  new HttpLink({
    uri: `${url}/graphql`,
    fetch,
  });

const authMiddleware = (type = '') =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
      const token = new TokenService('C2FOAuthToken').getToken();
      const tokenHeader = {
        Authorization: `Bearer ${token}` || '',
      };

      return {
        headers: {
          ...headers,
          ...tokenHeader,
        },
      };
    });

    return forward(operation);
  });

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    /** ---------- THIS IS LEFT COMMENTED, MIGHT BE USED LATER ---------- */
    // for (let err of graphQLErrors) {
    //   // switch (err.extensions.code) {
    //   //   // Apollo Server sets code to UNAUTHENTICATED
    //   //   // when an AuthenticationError is thrown in a resolver
    //   //   case 'UNAUTHENTICATED':
    //   //     // Modify the operation context with a new token
    //   //     const oldHeaders = operation.getContext().headers;
    //   //     operation.setContext({
    //   //       headers: {
    //   //         ...oldHeaders,
    //   //         authorization: getNewToken(),
    //   //       },
    //   //     });
    //   //     // Retry the request, returning the new observable
    //   //     return forward(operation);
    //   // }
    // }
  }

  // if (networkError) {
  //   console.log(`[Network error]: ${networkError}`);
  // }
});

const getClient = (url: string, type?: string): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, authMiddleware(type), getHttpLink(url)]),
    defaultOptions,
  });
};

const useClient: ApolloClient<NormalizedCacheObject> = getClient(config.AO_GATEWAY_API_URL);

const ApiApolloProvider: React.FC = ({ children }: { children?: React.ReactNode }) => (
  <ApolloProvider client={useClient}>{children}</ApolloProvider>
);

export { useClient, ApiApolloProvider };
