import {
  CampaignType,
  Occurrence,
  RepetitionStrategy,
  RepetitionInterval,
  DayOfWeek,
  WeekOfMonth,
  OfferType,
} from '../../../generated-types/api-types-graphql';

export const campaignType = [
  {
    label: 'New Participation',
    value: CampaignType.NewParticipation,
  },
  {
    label: 'Repeat Participation',
    value: CampaignType.RepeatParticipation,
  },
  {
    label: 'Non Registered User',
    value: CampaignType.NonRegisteredUser,
  },
];

export const offerType = [
  {
    label: 'Recurring',
    value: OfferType.Repeating,
  },
  {
    label: 'One Time',
    value: OfferType.OneTime,
  },
];

export const occurrence = [
  {
    label: 'Recurring',
    value: Occurrence.Recurring,
  },
  {
    label: 'One Time',
    value: Occurrence.OneTime,
  },
];

export const timeZone = [
  {
    label: 'Indian Standard Time (IST)',
    value: 'Asia/Kolkata',
  },
  {
    label: 'China Standard Time (CST)',
    value: 'Asia/Hong_Kong',
  },
  {
    label: 'Central Time (CST)',
    value: 'America/Chicago',
  },
  {
    label: 'Central European Time (CET)',
    value: 'Europe/Berlin',
  },
];

export const repeatOptions = [
  {
    label: 'Daily',
    value: RepetitionStrategy.Daily,
  },
  {
    label: 'Weekly',
    value: RepetitionStrategy.Weekly,
  },
  {
    label: 'Monthly',
    value: RepetitionStrategy.Monthly,
  },
];

export const everyOptions = [
  {
    label: '1',
    value: RepetitionInterval.EveryOne,
  },
  {
    label: '2',
    value: RepetitionInterval.EveryTwo,
  },
  {
    label: '3',
    value: RepetitionInterval.EveryThree,
  },
  {
    label: '4',
    value: RepetitionInterval.EveryFour,
  },
  {
    label: '5',
    value: RepetitionInterval.EveryFive,
  },
];

export const dayOptions = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

export const WeekOfMonthOptions = [
  {
    label: 'First',
    value: WeekOfMonth.First,
  },
  {
    label: 'Second',
    value: WeekOfMonth.Second,
  },
  {
    label: 'Third',
    value: WeekOfMonth.Third,
  },
  {
    label: 'Fourth',
    value: WeekOfMonth.Fourth,
  },
];

export const WeekOptions = [
  WeekOfMonth.First,
  WeekOfMonth.Second,
  WeekOfMonth.Third,
  WeekOfMonth.Fourth,
  WeekOfMonth.Last,
];
