import { HttpService } from '@c2fo/react-services';
import { config } from '../../../config';

export interface SegmentsData {
  id: string;
  name: string;
}
export class SegmentsService {
  constructor(private httpService: HttpService) {}

  async getSegments(uuid: string): Promise<SegmentsData[]> {
    try {
      const { data } = await this.httpService.get<SegmentsData[]>(
        `${config.ADMIN_API_URL}/offer-campaign-testing/srmHomeSegments/${uuid}`,
      );

      return data;
    } catch (error) {
      throw new Error('error while fetching segments');
    }
  }

  // TODO: remove after migration is complete

  async initiateOffer(srmHomeUserId: string, segmentId: string, debugMode: boolean) {
    try {
      const { data } = await this.httpService.post<SegmentsData[]>(
        `${config.ADMIN_API_URL}/offer-campaign-testing/initiate`,
        {
          data: {
            srmHomeUserId,
            segmentId,
            debugMode,
          },
        },
      );

      return data;
    } catch (error) {
      throw new Error('something went wrong');
    }
  }
}
