/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-statements */
import React, { useRef, useState } from 'react';
import {
  Box,
  PrimaryButton,
  makeStyles,
  Theme,
  IconButton,
  ArrowLeftIcon,
  Paper,
  SecondaryButton,
  useSnackbar,
  TypePageTitle,
  TypeWidgetHeader,
} from '@c2fo/react-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useMutation as useRestMutation } from 'react-query';
import { SegmentTable } from './SegmentTable/SegmentTable';
import { ConfirmationModal } from '../../components/ConfirmationModal/ConfirmationModal';
import { useServices } from '../../services';
import { ROUTES } from '../../Router';
import { INITIATE_OFFER } from '../../services/mutations/initiateOffer';
import { FileInput } from '../../components/FileInput/FileInput';
import { INITIATE_OFFER_VIA_CSV } from '../../services/mutations/initiateOfferWithCSV';

const useStyles = makeStyles<Theme>((theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  tableBody: {
    padding: theme.spacing(3),
    margin: theme.spacing(3, 7),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(0, 7),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  },
  cencelButton: {
    marginRight: 12,
  },
  icon: {
    marginRight: 8,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(4),
  },
  sectionMargin: {
    marginBottom: theme.spacing(2),
  },
}));

export const testIds = {
  cancelButon: 'segmentspage-cancel-button',
  runButton: 'segmentspage-run-button',
  pageTitle: 'segmentspage-title',
};

export const SegmentsPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();

  const uploadImageRef = useRef<HTMLInputElement>(null);
  const [{ fileName, fileData }, setFile] = useState<{ fileName: string; fileData?: File }>({
    fileName: '',
  });

  const [modalOpen, setModalOpen] = useState(false);
  const handleBack = () => history.goBack();
  const { fileUploadService } = useServices();

  const { srmUserId } = useParams<{ uuid: string; srmUserId: string }>();

  const { tokenService } = useServices();
  const userAuthServiceUuid = tokenService.getTokenContent()?.payload.user.uuid;

  const queryParams = new URLSearchParams(useLocation().search);
  const [{ selectedSegmentId, selectedSegmentName }, setSegmentId] = useState({
    selectedSegmentId: '',
    selectedSegmentName: '',
  });

  const handleSuccess = () => {
    openSnackbar(<> your campaign is live &#127881; &#127881;</>, { variant: 'success' });
    history.push(ROUTES.ACTIONABLE_OFFER);
    setLoading(false);
    setModalOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any) => {
    openSnackbar(<> {error?.message ?? 'Something went wrong'}</>, { variant: 'error' });
    setLoading(false);
    setModalOpen(false);
  };

  const [initiateActionableOffer, { loading: isInitiateOffer }] = useMutation(INITIATE_OFFER, {
    onCompleted: handleSuccess,
    onError: handleError,
  });

  const [initiateActionableOfferWithCSV, { loading: isInitiateOfferWithCSV }] = useMutation(INITIATE_OFFER_VIA_CSV, {
    onCompleted: handleSuccess,
    onError: handleError,
  });

  const isDebugMode = queryParams.get('isDebugMode') === 'true';

  const { mutate: uploadCSV, isLoading: isCSVUploading } = useRestMutation(
    (data: FormData) => fileUploadService.upload(data),
    {
      onSuccess: (data: any) => {
        if (!data.data.uuid) {
          return;
        }
        initiateActionableOfferWithCSV({
          variables: {
            name: data?.data?.displayName,
            srmHomeUserUuid: srmUserId || '',
            file: {
              uuid: data.data.uuid,
            },
            isDebugMode,
          },
        });
      },
      onError: handleError,
    },
  );

  const runOffer = async () => {
    setLoading(true);

    if (fileName) {
      const requestPayload = new FormData();

      requestPayload.append('file', fileData || '');

      uploadCSV(requestPayload);
    } else {
      try {
        initiateActionableOffer({
          variables: {
            name: selectedSegmentName,
            srmHomeUserUuid: srmUserId || '',
            authServiceUuid: userAuthServiceUuid,
            segmentId: selectedSegmentId,
            isDebugMode,
            offerUuid: '',
            currentDate: '',
          },
        });
      } catch (error) {
        handleError(error);
      }
    }
  };

  const shouldRun = selectedSegmentId || fileName;

  const isLoading = isInitiateOffer || loading || isCSVUploading || isInitiateOfferWithCSV;

  return (
    <Box className={classes.body}>
      <ConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedSegmentName={selectedSegmentName}
        isSchedule={false}
        runOffer={runOffer}
        loading={isLoading}
      />

      <Box className={classes.header}>
        <IconButton className={classes.icon} onClick={handleBack}>
          <ArrowLeftIcon color="primary" />
        </IconButton>
        <TypePageTitle data-testid={testIds.pageTitle}>Segments</TypePageTitle>
      </Box>
      <Paper className={classes.tableBody}>
        <FileInput uploadImageRef={uploadImageRef} fileName={fileName} setFile={setFile} setSegmentId={setSegmentId} />
        <TypeWidgetHeader align="center">OR</TypeWidgetHeader>
        <Box className={classes.header}>
          <TypeWidgetHeader>Segments</TypeWidgetHeader>
        </Box>
        <SegmentTable isDisabled={!!fileName} selectedSegmentId={selectedSegmentId} setSegmentId={setSegmentId} />
      </Paper>
      <Box className={classes.footer}>
        <SecondaryButton data-testid={testIds.cancelButon} className={classes.cencelButton} onClick={handleBack}>
          Cancel
        </SecondaryButton>
        <PrimaryButton data-testid={testIds.runButton} disabled={!shouldRun} onClick={() => setModalOpen(true)}>
          Run
        </PrimaryButton>
      </Box>
    </Box>
  );
};
