import React from 'react';
import { Helmet } from 'react-helmet';
import { C2foComponentsRootProvider, ErrorBoundary, SnackbarProvider } from '@c2fo/react-components';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ServicesProvider } from './services';
import { Router } from './Router';
import { GlobalErrorPage } from './pages';

import faviconSm from '../assets/favicon-150x150.png';
import faviconLg from '../assets/favicon-300x300.png';
import { ApiApolloProvider } from './services/Services.client';
import { SplitProvider } from '../providers/SplitProvider/SplitProvider';

const queryClient = new QueryClient();

export const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary render={() => <GlobalErrorPage />}>
      <AppHead />
      <ApiApolloProvider>
        <ServicesProvider>
          <C2foComponentsRootProvider theme={'liquidity'}>
            <SnackbarProvider>
              <SplitProvider>
                <Router />
              </SplitProvider>
            </SnackbarProvider>
          </C2foComponentsRootProvider>
        </ServicesProvider>
      </ApiApolloProvider>
    </ErrorBoundary>
  </QueryClientProvider>
);

const AppHead: React.FC = () => {
  return (
    <Helmet>
      <title>Actionable Offer</title>
      <base href="/" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link href={faviconLg} data-favicon-id="lg" rel="icon" type="image/x-icon" sizes="192x192" />
      <link href={faviconSm} data-favicon-id="sm" rel="icon" type="image/x-icon" sizes="32x32" />
      <link href={faviconLg} data-favicon-id="lg" rel="apple-touch-icon" />
    </Helmet>
  );
};
