import { gql } from '@apollo/client';

export const GET_UPCOMING_CAMPAIGNS = gql`
  query actionableOfferSchedules($paging: CursorPaging, $sorting: [ActionableOfferScheduleSort!]) {
    actionableOfferSchedules(paging: $paging, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          name
          campaignType
          campaignCode
          uuid
          srmHomeUserUuid
          occurrence
          startDate
          endDate
          timeZone
          executionTime
          repetitionStrategy
          repetitionInterval
          executionDays
          owner
          isDebugMode
          nextExecutionMilliseconds
          srmHomeSegmentName
          srmHomeSegmentId
          tags {
            tag {
              name
              id
            }
          }
        }
      }
    }
  }
`;
