import { gql } from '@apollo/client';

export const GET_TAGS = gql`
  query actionableOfferScheduleTags($paging: CursorPaging, $sorting: [ActionableOfferScheduleTagsSort!]) {
    actionableOfferScheduleTags(paging: $paging, sorting: $sorting) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
