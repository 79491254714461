import { gql } from '@apollo/client';

export const GET_OFFER_HISTORY = gql`
  query actionableOfferScheduleHistories(
    $paging: CursorPaging
    $sorting: [ActionableOfferScheduleHistorySort!]
    $filter: ActionableOfferScheduleHistoryFilter
  ) {
    actionableOfferScheduleHistories(paging: $paging, sorting: $sorting, filter: $filter) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          uuid
          status
          executedOn
          offer {
            name
            campaignType
            campaignCode
            occurrence
            owner
            srmHomeSegmentId
            uuid
            srmHomeSegmentName
            isDebugMode
            timeZone
          }
        }
      }
    }
  }
`;

export const MARK_AS_STOP = gql`
  mutation markStopStatusOfOngoingCampaign($uuid: String!) {
    markStopStatusOfOngoingCampaign(update: { uuid: $uuid }) {
      uuid
    }
  }
`;
