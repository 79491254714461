import { env } from '@c2fo/common-config';

export const config = {
  ADMIN_APP: env.getString('LEGACY_C2FO_ADMIN_UI_URL') || 'http://localhost:8088',
  ADMIN_API_URL: env.getString('ADMIN_API_URL') || 'http://localhost:9088',
  AO_API_URL: env.getString('AO_API_URL') || 'http://localhost:9009',
  AO_GATEWAY_API_URL: env.getString('AO_GATEWAY_API_URL') || 'http://localhost:9009',
  IS_DEVELOPMENT: env.getString('NODE_ENV') === 'development',
  SPLIT_IO_KEY: env.getString('SPLIT_IO_KEY'),
};
