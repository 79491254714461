import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TypeBase,
  TableContainer,
  makeStyles,
  Theme,
  Box,
  Radio,
  TypeLabel,
  CircularProgress,
  Liquidity,
  TypeHelper,
} from '@c2fo/react-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SEGMENTS_FOR_USER } from '../../../services/queries/getSegmentsForUser';

const useStyles = makeStyles<Theme, { isDisabled: boolean }>(() => ({
  tableHead: {
    borderTop: 'solid 1px black',
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    cursor: 'pointer',
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    opacity: ({ isDisabled }) => (isDisabled ? 0.5 : 1),
  },
}));

interface SegmentTableProps {
  selectedSegmentId: string;
  setSegmentId: (data: { selectedSegmentId: string; selectedSegmentName: string }) => void;
  isDisabled?: boolean;
}

export const testIds = {
  tableRow: 'segmentTable-row',
};

export const SegmentTable: React.FC<SegmentTableProps> = ({
  selectedSegmentId,
  setSegmentId,
  isDisabled = false,
}: SegmentTableProps) => {
  const classes = useStyles({ isDisabled });

  const { srmUserId } = useParams<{ uuid: string; srmUserId: string }>();

  const { data, loading, error } = useQuery(GET_SEGMENTS_FOR_USER, {
    variables: {
      authServiceUuid: srmUserId,
    },
  });

  const segments = data?.getSegmentsForUser ?? [];

  if (error) {
    return (
      <Box className={classes.loaderContainer}>
        <TypeHelper customColor={Liquidity.colors.feedback.error}>{error.message}</TypeHelper>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box className={classes.loaderContainer}>
        <CircularProgress size={24} />
        <TypeLabel>Loading...</TypeLabel>
      </Box>
    );
  }

  if (segments && segments.length === 0) {
    return (
      <Box>
        <TypeLabel>No Data found</TypeLabel>
      </Box>
    );
  }

  return (
    <TableContainer className={classes.container}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {segments?.map((item: { id: string; name: string }) => (
            <TableRow
              data-testid={`${testIds.tableRow}${item.id}`}
              key={item.id}
              onClick={
                isDisabled
                  ? () => null
                  : () => setSegmentId({ selectedSegmentId: item.id, selectedSegmentName: item.name })
              }
              className={classes.row}
            >
              <TableCell>
                <Box className={classes.checkBoxContainer}>
                  <Radio disabled={isDisabled} checked={item.id === selectedSegmentId} />
                  <TypeBase>{item.name}</TypeBase>
                </Box>
              </TableCell>
              <TableCell>
                <TypeBase>{item.id}</TypeBase>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
