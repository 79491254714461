import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel, makeStyles } from '@c2fo/react-components';
import { SORT_DIRECTION, AO_TABLE_HEADERS_CELLS } from '../../../../constants';

const useStyles = makeStyles(() => ({
  tableHead: {
    borderTop: 'solid 1px rgb(0, 58, 80)',
    borderBottom: 'solid 1px rgb(0, 58, 80)',
  },
}));

interface UpcomingCampaignsTableHeaderProps {
  sortCellId: number;
  sortDirection: SORT_DIRECTION;
  handleSortSelection: React.Dispatch<
    React.SetStateAction<{
      field: string;
      direction: SORT_DIRECTION;
      fieldId: number;
    }>
  >;
}

export const UpcomingCampaignsTableHeader: React.FC<UpcomingCampaignsTableHeaderProps> = ({
  sortCellId,
  sortDirection,
  handleSortSelection,
}: UpcomingCampaignsTableHeaderProps) => {
  const classes = useStyles();
  const { ASC, DESC } = SORT_DIRECTION;

  /* 
  if cell is preseleted reverse the sort direction
  else return default sort direction for new selected cell
  */

  const getSortDirection = (id: number) => {
    if (id === sortCellId) {
      return sortDirection === ASC ? DESC : ASC;
    }
    return ASC;
  };

  const handleSortClick = (isSortEnabled: boolean, fieldId: number, field: string) => {
    if (isSortEnabled) {
      handleSortSelection({ fieldId, direction: getSortDirection(fieldId), field });
    }
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {AO_TABLE_HEADERS_CELLS.map(({ id, label, isSortEnabled, dbField }) => {
          return (
            <TableCell key={id} onClick={() => handleSortClick(isSortEnabled, id, dbField)}>
              {isSortEnabled ? (
                <TableSortLabel data-testid={id} active={id === sortCellId} direction={sortDirection}>
                  {label}
                </TableSortLabel>
              ) : (
                label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
