/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import React, { useRef, useState } from 'react';
import {
  ArrowLeftIcon,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  KeyboardDatePicker,
  Liquidity,
  makeStyles,
  MuiPickersUtilsProvider,
  Paper,
  PrimaryButton,
  TertiaryButton,
  Theme,
  TimePicker,
  TypeLabel,
  TypePageTitle,
  TypeWidgetHeader,
  useSnackbar,
} from '@c2fo/react-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { useMutation, useQuery } from '@apollo/client';
import { useMutation as useRestMutation } from 'react-query';
import { TextField } from '../../components/TextField/TextField';
import { Select } from '../../components/Select/Select';
import {
  campaignType,
  dayOptions,
  everyOptions,
  occurrence,
  offerType,
  repeatOptions,
  timeZone,
  WeekOptions,
} from './formData';
import { SegmentTable } from '../SegmentsPage/SegmentTable/SegmentTable';
import { GET_OFFER_DETAILS } from '../../services/queries/offerDetails';
import {
  ActionableOfferScheduleInput,
  CampaignType,
  Occurrence,
  OfferType,
  RepetitionStrategy,
  ScheduleTagsMappingEntity,
  Source,
} from '../../../generated-types/api-types-graphql';
import { ConfirmationModal } from '../../components';
import { CREATE_OFFER } from '../../services/mutations/createOffer';
import { UPDATE_OFFER } from '../../services/mutations/updateOffer';
import {
  dateFormatter,
  decodeCampaignCode,
  getCampaignCode,
  getConfimationMessage,
  getDateFromTime,
  snakeToCamel,
} from '../../utils/utils';
import { ROUTES } from '../../Router';
import { TagsInput } from '../../components/TagsInput/TagsInput';
import { FileInput } from '../../components/FileInput/FileInput';
import { useServices } from '../../services';

const useStyles = makeStyles<Theme>((theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 4, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  campaignContainer: {
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(3),
    margin: theme.spacing(3, 7, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
    },
  },
  input: {
    width: '40%',
  },
  buttonSubmit: {
    marginRight: theme.spacing(1.4),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  select: {
    width: 300,
    minHeight: 56,
    backgroundColor: 'white',
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  datePicker: {
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(2),
      width: 320,
      paddingRight: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .MuiOutlinedInput-root': {
      padding: 0,
      borderRadius: 3,
      width: 320,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .MuiInputAdornment-root button': {
      padding: theme.spacing(1),
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  timeSelection: {
    width: 200,
    minHeight: 56,
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timeZone: {
    width: 200,
    minHeight: 56,
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: theme.spacing(3, 7, 0),
  },
  tableHead: {
    borderTop: 'solid 1px black',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    height: 86,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
      marginRight: theme.spacing(0),
    },
  },
  timeZoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  icon: {
    marginRight: 8,
  },
  tertiaryBotton: {
    marginRight: 12,
  },
  label: { marginBottom: 4 },
  autoComplete: {
    minWidth: 200,
    marginTop: theme.spacing(0.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export const testIds = {
  repetitionStrategy: 'RepetitionStrategy',
  repetitionInterval: 'RepetitionInterval',
  weekOfMonth: 'weekOfMonth',
  executionDays: 'executionDays',
  occurrence: 'occurrence',
  campaignName: 'campaignName',
  timeZone: 'timeZone',
  offerType: 'offerType',
};

export const fieldType = {
  REQUIRED: 'Required',
};

export const SchedulerFormPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [formData, setFormData] = useState({
    campaignType: CampaignType.NewParticipation,
    newParticipation: false,
    repeatParticipation: false,
    nonRegisteredUser: false,
    name: '',
    occurrence: Occurrence.Recurring,
    offerType: OfferType.OneTime,
    startDate: Date.now(),
    endDate: Date.now(),
    time: Date.now(),
    timeZone: '',
    repetitionStrategy: '',
    repetitionInterval: '',
    executionTime: Date.now(),
    executionDays: [],
    // weekOfMonth: '',
    weekOfMonth: [],
    srmHomeUserUuid: '',
    srmHomeSegmentId: '',
    tags: [],
  });

  const uploadImageRef = useRef<HTMLInputElement>(null);
  const [{ fileName, fileData }, setFile] = useState<{ fileName: string; fileData?: File }>({
    fileName: '',
  });

  const { openSnackbar } = useSnackbar();
  const { uuid, srmUserId } = useParams<{ uuid: string; srmUserId: string }>();
  const [modalOpen, setModalOpen] = useState(false);

  const pageTitle = uuid ? 'Edit' : 'Schedule Actionable offer';
  const submitButtonTitle = uuid ? 'Done' : 'Schedule';

  const queryParams = new URLSearchParams(useLocation().search);

  const isDebugMode = queryParams.get('isDebugMode') === 'true';

  const { fileUploadService } = useServices();

  const [createOneActionableOfferSchedule] = useMutation(CREATE_OFFER, {
    onCompleted: () => handleSnackbar('success'),
    onError: (error) => handleSnackbar('error', error.message),
  });

  const [updateOneActionableOfferSchedule] = useMutation(UPDATE_OFFER, {
    onCompleted: () => handleSnackbar('success'),
    onError: (error) => handleSnackbar('error', error.message),
  });

  const [{ selectedSegmentId, selectedSegmentName }, setSegmentId] = useState({
    selectedSegmentId: '',
    selectedSegmentName: '',
  });

  const { data: offerDetails } = useQuery(GET_OFFER_DETAILS, {
    fetchPolicy: 'no-cache',
    skip: !uuid,
    variables: {
      id: uuid,
    },
    onCompleted: () => {
      setSegmentId({
        selectedSegmentId: offerDetails?.actionableOfferSchedule?.srmHomeSegmentId ?? '',
        selectedSegmentName,
      });
      setFormData({
        ...offerDetails?.actionableOfferSchedule,
        ...decodeCampaignCode(offerDetails?.actionableOfferSchedule?.campaignCode),
        executionDays: offerDetails?.actionableOfferSchedule?.executionDays ?? [],
        executionTime: getDateFromTime(offerDetails?.actionableOfferSchedule?.executionTime),
        tags: offerDetails?.actionableOfferSchedule?.tags.map((tagItem: ScheduleTagsMappingEntity) => ({
          id: +tagItem.tag.id,
          name: tagItem.tag.name,
        })),
      });
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: !!uuid,
    initialValues: formData,
    onSubmit: (values) => {
      const tags = values.tags.length > 0 ? values.tags : null;

      const getFormData = () => {
        const generaliseValues: ActionableOfferScheduleInput = {
          campaignCode: getCampaignCode(values.newParticipation, values.repeatParticipation, values.nonRegisteredUser),
          campaignType: values.campaignType,
          executionTime: `${dateFormatter(values.executionTime, 'HH:mm')}:00`,
          isDebugMode,
          name: values.name,
          occurrence: values.occurrence,
          offerType: values.offerType,
          srmHomeUserUuid: formData.srmHomeUserUuid || srmUserId,
          startDate: dateFormatter(values.startDate),
          tags,
          timeZone: values.timeZone,
        };

        if (values.occurrence === Occurrence.OneTime) {
          return generaliseValues;
        }

        if (values.occurrence === Occurrence.Recurring) {
          if (values.repetitionStrategy === repeatOptions[0].value) {
            return {
              ...generaliseValues,
              endDate: dateFormatter(values.endDate),
              repetitionStrategy: values.repetitionStrategy,
              repetitionInterval: values.repetitionInterval,
            };
          }
          if (values.repetitionStrategy === repeatOptions[1].value) {
            return {
              ...generaliseValues,
              endDate: dateFormatter(values.endDate),
              repetitionStrategy: values.repetitionStrategy,
              executionDays: values.executionDays,
            };
          }
        }

        return {
          name: values.name,
          srmHomeUserUuid: formData.srmHomeUserUuid || srmUserId,
          campaignCode: getCampaignCode(values.newParticipation, values.repeatParticipation, values.nonRegisteredUser),
          occurrence: values.occurrence,
          offerType: values.offerType,
          startDate: dateFormatter(values.startDate),
          endDate: dateFormatter(values.endDate),
          timeZone: values.timeZone,
          executionTime: `${dateFormatter(values.executionTime, 'HH:mm')}:00`,
          repetitionStrategy: values.repetitionStrategy,
          repetitionInterval: values.repetitionInterval,
          executionDays: values.executionDays,
          isDebugMode,
          weekOfMonth: values.weekOfMonth,
          tags,
        };
      };

      const createOneAO = (extraParams?: Record<string, unknown>) => {
        createOneActionableOfferSchedule({
          variables: {
            ...getFormData(),
            ...extraParams,
          },
        });
      };

      const updateOneAO = (extraParams?: Record<string, unknown>) => {
        updateOneActionableOfferSchedule({
          variables: {
            id: uuid,
            offerUuid: uuid,
            ...getFormData(),
            ...extraParams,
          },
        });
      };

      /*
    if filename present we will
    upload csv first, will get uuid and pass it to schedular
    */
      if (fileName) {
        const requestPayload = new FormData();

        requestPayload.append('file', fileData || '');

        uploadCSV(requestPayload, {
          onSuccess: (data: any) => {
            if (uuid) {
              if (!data.data.uuid) {
                return;
              }
              updateOneAO({
                srmHomeSegmentId: null,
                source: Source.Csv,
                file: {
                  uuid: data.data.uuid,
                },
              });
            } else {
              createOneAO({
                srmHomeSegmentId: null,
                source: Source.Csv,
                file: {
                  uuid: data.data.uuid,
                },
              });
            }
          },
        });
      } else if (!fileName && uuid) {
        updateOneAO({
          source: Source.Segment,
          srmHomeSegmentId: selectedSegmentId || formData.srmHomeSegmentId,
        });
      } else {
        createOneAO({ source: Source.Segment, srmHomeSegmentId: selectedSegmentId || formData.srmHomeSegmentId });
      }
    },
    validate: (values) => {
      const errors: {
        name?: string;
        endDate?: string;
        campaignType?: string;
        timeZone?: string;
        weekOfMonth?: string;
        repetitionInterval?: string;
        repetitionStrategy?: string;
        executionDays?: string;
        offerType?: string;
      } = {};
      if (!values.name) {
        errors.name = fieldType.REQUIRED;
      }
      if (values.occurrence === Occurrence.Recurring && !values.repetitionStrategy) {
        errors.repetitionStrategy = fieldType.REQUIRED;
      }
      if (values.offerType === OfferType.OneTime && !values.offerType) {
        errors.offerType = fieldType.REQUIRED;
      }

      if (!values.timeZone) {
        errors.timeZone = fieldType.REQUIRED;
      }
      if (!(values.newParticipation || values.repeatParticipation || values.nonRegisteredUser)) {
        errors.campaignType = 'At least one campaign type should be selected';
      }
      if (values.occurrence === Occurrence.Recurring && values.endDate < values.startDate) {
        errors.endDate = 'End Date cannot be less than Start Date';
      }
      if (values.repetitionStrategy === RepetitionStrategy.Monthly) {
        if (!values.weekOfMonth || values.weekOfMonth.length === 0) {
          errors.weekOfMonth = fieldType.REQUIRED;
        }
        if (!values.repetitionInterval) {
          errors.repetitionInterval = fieldType.REQUIRED;
        }
        if (!values.executionDays || values.executionDays.length === 0) {
          errors.executionDays = fieldType.REQUIRED;
        }
      }
      if (values.repetitionStrategy === RepetitionStrategy.Daily) {
        if (!values.repetitionInterval) {
          errors.repetitionInterval = fieldType.REQUIRED;
        }
      }
      if (values.repetitionStrategy === RepetitionStrategy.Weekly) {
        if (!values.executionDays || values.executionDays.length === 0) {
          errors.executionDays = fieldType.REQUIRED;
        }
      }
      return errors;
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = formik;

  const getValueForCheckbox = (checkboxLabel: string) => {
    let checkboxValue = false;
    if (checkboxLabel === CampaignType.NewParticipation && values.newParticipation) {
      checkboxValue = true;
    }
    if (checkboxLabel === CampaignType.RepeatParticipation && values.repeatParticipation) {
      checkboxValue = true;
    }
    if (checkboxLabel === CampaignType.NonRegisteredUser && values.nonRegisteredUser) {
      checkboxValue = true;
    }
    return checkboxValue;
  };
  const handleSnackbar = (variant: 'error' | 'success', message?: string) => {
    setModalOpen(false);
    setSubmitting(false);
    if (queryParams.get('isDebugMode') === 'false') {
      openSnackbar(
        variant === 'success' ? (
          <>
            your campaign is scheduled
            {values.occurrence === Occurrence.Recurring
              ? ` from ${dateFormatter(values.startDate)} to ${dateFormatter(values.endDate)} `
              : ` for  ${dateFormatter(values.startDate)} `}
            &#127881; &#127881;
          </>
        ) : (
          <>{message ?? 'Failed'}</>
        ),
        {
          key: 'offer-confirmation-alert',
          variant,
        },
      );
    }

    if (variant === 'success') {
      history.push(ROUTES.ACTIONABLE_OFFER);
    }
  };

  const handleBack = () => history.goBack();

  const { mutate: uploadCSV, isLoading: isCSVUploading } = useRestMutation(
    (data: FormData) => fileUploadService.upload(data),
    {
      onError: (error: { message: string }) => handleSnackbar('error', error.message),
    },
  );

  const runOffer = () => {
    formik.submitForm();
  };

  const isLoading = isSubmitting || isCSVUploading;

  const campaignTypes = campaignType.map((entry) => {
    const key = entry.value;
    return (
      <FormControlLabel
        key={key}
        control={
          <Checkbox
            checked={getValueForCheckbox(entry.value)}
            onBlur={handleBlur}
            onChange={(event) => {
              setFieldValue(snakeToCamel(entry.value.toLowerCase()), event.target.checked);
            }}
            color={'primary'}
            name={key}
            data-testid={key}
          />
        }
        label={entry.label}
      />
    );
  });

  return (
    <Box className={classes.body}>
      <ConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedSegmentName={selectedSegmentName}
        isSchedule={true}
        runOffer={runOffer}
        loading={isLoading}
        message={getConfimationMessage(values)}
      />
      <Box className={classes.header}>
        <IconButton className={classes.icon} onClick={handleBack}>
          <ArrowLeftIcon color="primary" />
        </IconButton>
        <TypePageTitle>{pageTitle}</TypePageTitle>
      </Box>

      <form onSubmit={handleSubmit}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Paper className={classes.campaignContainer}>
            <TypeWidgetHeader style={{ marginBottom: 12 }}>Schedule Actionable offer</TypeWidgetHeader>
            <Box>
              <TypeLabel classes={{ root: classes.label }}>Campaign Type</TypeLabel>
              <FormGroup row>{campaignTypes}</FormGroup>
            </Box>
            <Box className={classes.textInputContainer}>
              <TextField
                error={touched.name && errors.name !== undefined}
                data-testid={testIds.campaignName}
                variant="outlined"
                name="name"
                label="Name of the campaign"
                placeholder="Type here"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                required
              />
              <TypeLabel customColor={Liquidity.colors.feedback.error}>
                {errors.name && touched.name && errors.name}
              </TypeLabel>
            </Box>
          </Paper>
          <Paper className={classes.campaignContainer}>
            <TypeWidgetHeader style={{ marginBottom: 12 }}>Scheduling details</TypeWidgetHeader>
            <Box className={classes.rowContainer}>
              <Box className={classes.inputContainer}>
                <TypeLabel classes={{ root: classes.label }}>Occurrence</TypeLabel>
                <Select
                  data-testid={testIds.occurrence}
                  className={classes.select}
                  name="occurrence"
                  placeholder="Select"
                  onChange={(event) => {
                    setFieldValue('occurrence', event.target.value);
                    setFieldValue('repetitionStrategy', '');
                    setFieldValue('repetitionInterval', '');
                    setFieldValue('weekOfMonth', []);
                    setFieldValue('executionDays', []);
                  }}
                  onBlur={handleBlur}
                  value={values.occurrence}
                  options={occurrence}
                />
              </Box>
              <Box className={classes.inputContainer}>
                <TypeLabel classes={{ root: classes.label }}>Offer Type</TypeLabel>
                <Select
                  data-testid={testIds.offerType}
                  className={classes.select}
                  name="offerType"
                  placeholder="Select"
                  onChange={(event) => {
                    setFieldValue('offerType', event.target.value);
                  }}
                  required
                  onBlur={handleBlur}
                  value={values.offerType}
                  options={offerType}
                />
              </Box>
            </Box>
            <Box className={classes.rowContainer}>
              <Box className={classes.inputContainer}>
                <TypeLabel> {values.occurrence === Occurrence.OneTime ? 'Date' : 'Start'}</TypeLabel>
                <KeyboardDatePicker
                  error={touched.startDate && errors.startDate !== undefined}
                  className={classes.datePicker}
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="dd/MM/yyyy"
                  minDate={uuid ? null : new Date()}
                  name="startDate"
                  margin="dense"
                  value={values.startDate}
                  onChange={(startDate) => {
                    setFieldValue('startDate', startDate);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change from date',
                  }}
                />
              </Box>
              {values.occurrence === Occurrence.Recurring && (
                <Box className={classes.inputContainer}>
                  <TypeLabel>End</TypeLabel>
                  <KeyboardDatePicker
                    error={touched.endDate && errors.endDate !== undefined}
                    className={classes.datePicker}
                    disableToolbar
                    minDate={uuid ? null : new Date()}
                    inputVariant="outlined"
                    variant="inline"
                    format="dd/MM/yyyy"
                    name="endDate"
                    margin="dense"
                    value={values.endDate}
                    onChange={(endDate) => {
                      setFieldValue('endDate', endDate);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change to date',
                    }}
                  />
                  <TypeLabel customColor={Liquidity.colors.feedback.error}>
                    {(touched.endDate && errors?.endDate) ?? ' '}
                  </TypeLabel>
                </Box>
              )}
            </Box>
            <Box className={classes.rowContainer}>
              <Box className={classes.inputContainer}>
                <TypeLabel classes={{ root: classes.label }}>Time Zone</TypeLabel>
                <Select
                  error={touched.timeZone && errors.timeZone !== undefined}
                  data-testid={testIds.timeZone}
                  className={classes.timeSelection}
                  name="timeZone"
                  placeholder="Select"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.timeZone}
                  options={timeZone}
                />
                <TypeLabel customColor={Liquidity.colors.feedback.error}>
                  {errors.timeZone && touched.timeZone && errors.timeZone}
                </TypeLabel>
              </Box>
              <Box className={classes.timeZoneContainer}>
                <TypeLabel classes={{ root: classes.label }}>Time</TypeLabel>
                <TimePicker
                  className={classes.timeZone}
                  id="time-picker"
                  inputVariant="outlined"
                  value={values.executionTime}
                  onChange={(time) => {
                    setFieldValue('executionTime', time);
                  }}
                />
              </Box>
            </Box>
            {values.occurrence === Occurrence.Recurring && (
              <Box className={classes.rowContainer}>
                <Box className={classes.inputContainer}>
                  <TypeLabel classes={{ root: classes.label }}>Repeat</TypeLabel>
                  <Select
                    error={touched.repetitionStrategy && errors.repetitionStrategy !== undefined}
                    data-testid={testIds.repetitionStrategy}
                    className={classes.timeSelection}
                    name="repetitionStrategy"
                    placeholder="Select"
                    onChange={(event) => {
                      setFieldValue('repetitionStrategy', event.target.value);
                      setFieldValue('weekOfMonth', []);
                      setFieldValue('executionDays', []);
                      setFieldValue('repetitionInterval', '');
                    }}
                    onBlur={handleBlur}
                    value={values.repetitionStrategy}
                    options={repeatOptions}
                  />
                  <TypeLabel customColor={Liquidity.colors.feedback.error}>
                    {errors.repetitionStrategy && touched.repetitionStrategy && errors.repetitionStrategy}
                  </TypeLabel>
                </Box>
                {values.repetitionStrategy !== '' && (
                  <>
                    {values.repetitionStrategy !== RepetitionStrategy.Weekly && (
                      <Box className={classes.inputContainer}>
                        <TypeLabel classes={{ root: classes.label }}>Every</TypeLabel>
                        <Select
                          error={touched.repetitionInterval && errors.repetitionInterval !== undefined}
                          data-testid={testIds.repetitionInterval}
                          className={classes.timeSelection}
                          name="repetitionInterval"
                          placeholder="Select"
                          onChange={(event) => {
                            setFieldValue('repetitionInterval', event.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.repetitionInterval}
                          options={everyOptions}
                        />
                        <TypeLabel customColor={Liquidity.colors.feedback.error}>
                          {errors.repetitionInterval && touched.repetitionInterval && errors.repetitionInterval}
                        </TypeLabel>
                      </Box>
                    )}
                    {values.repetitionStrategy === RepetitionStrategy.Monthly && (
                      <Box className={classes.inputContainer}>
                        <TypeLabel classes={{ root: classes.label }}>Week</TypeLabel>
                        <Autocomplete
                          multiple={true}
                          id="weekOfMonth"
                          data-testid={testIds.weekOfMonth}
                          options={WeekOptions}
                          value={values.weekOfMonth}
                          disableCloseOnSelect
                          onChange={(_, value) => {
                            setFieldValue('weekOfMonth', value);
                          }}
                          // For capitalizing options
                          getOptionLabel={(option) => option.slice(0, 1) + option.slice(1).toLowerCase()}
                          className={classes.autoComplete}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={touched.weekOfMonth && !errors.weekOfMonth}
                              variant="outlined"
                            />
                          )}
                        />
                        <TypeLabel customColor={Liquidity.colors.feedback.error}>
                          {errors.weekOfMonth && touched.weekOfMonth && errors.weekOfMonth}
                        </TypeLabel>
                      </Box>
                    )}
                    {values.repetitionStrategy !== RepetitionStrategy.Daily && (
                      <Box className={classes.inputContainer}>
                        <TypeLabel classes={{ root: classes.label }}>
                          {values.repetitionStrategy === RepetitionStrategy.Monthly ? 'On' : 'Day'}
                        </TypeLabel>
                        <Autocomplete
                          multiple={true}
                          data-testid={testIds.executionDays}
                          options={dayOptions}
                          value={values.executionDays}
                          disableCloseOnSelect
                          onChange={(_, value) => {
                            setFieldValue('executionDays', value);
                          }}
                          getOptionLabel={(option) => option.slice(0, 1) + option.slice(1).toLowerCase()}
                          className={classes.autoComplete}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                        <TypeLabel customColor={Liquidity.colors.feedback.error}>
                          {errors.executionDays && touched.executionDays && errors.executionDays}
                        </TypeLabel>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
            <Box className={classes.rowContainer}>
              <TagsInput values={values.tags} setValue={(tags) => setFieldValue('tags', tags)} />
            </Box>
          </Paper>
        </MuiPickersUtilsProvider>
        <Paper className={classes.campaignContainer}>
          <FileInput
            uploadImageRef={uploadImageRef}
            fileName={fileName}
            setFile={setFile}
            setSegmentId={setSegmentId}
          />
          <TypeWidgetHeader align="center">OR</TypeWidgetHeader>
          <TypeWidgetHeader style={{ marginBottom: 16 }}>Segments</TypeWidgetHeader>
          <SegmentTable selectedSegmentId={selectedSegmentId} setSegmentId={setSegmentId} isDisabled={!!fileName} />
        </Paper>
        <Box className={classes.buttonContainer}>
          <TertiaryButton className={classes.tertiaryBotton} onClick={handleBack}>
            Cancel
          </TertiaryButton>
          <PrimaryButton
            onClick={() => setModalOpen(true)}
            disabled={!(formik.isValid && (selectedSegmentId || fileName))}
          >
            {submitButtonTitle}
          </PrimaryButton>
        </Box>
      </form>
    </Box>
  );
};
