import { gql } from '@apollo/client';

const UPDATE_OFFER = gql`
  mutation updateOneActionableOfferSchedule(
    $id: ID!
    $name: String!
    $srmHomeUserUuid: String!
    $srmHomeSegmentId: String!
    $campaignType: CampaignType!
    $campaignCode: Float!
    $occurrence: Occurrence!
    $offerType: OfferType!
    $startDate: String!
    $endDate: String
    $timeZone: String!
    $executionTime: String!
    $repetitionStrategy: RepetitionStrategy
    $repetitionInterval: RepetitionInterval
    $executionDays: [DayOfWeek!]
    $isDebugMode: Boolean
    $weekOfMonth: [WeekOfMonth!]
    $tags: [ActionableOfferScheduleTagsCreateNew!]
  ) {
    updateOneActionableOfferSchedule(
      input: {
        id: $id
        update: {
          name: $name
          srmHomeUserUuid: $srmHomeUserUuid
          srmHomeSegmentId: $srmHomeSegmentId
          campaignType: $campaignType
          campaignCode: $campaignCode
          occurrence: $occurrence
          offerType: $offerType
          startDate: $startDate
          endDate: $endDate
          timeZone: $timeZone
          executionTime: $executionTime
          repetitionStrategy: $repetitionStrategy
          repetitionInterval: $repetitionInterval
          executionDays: $executionDays
          isDebugMode: $isDebugMode
          weekOfMonth: $weekOfMonth
          tags: $tags
        }
      }
    ) {
      uuid
    }
  }
`;

export { UPDATE_OFFER };
