import React from 'react';
import { useTreatments, SplitFactory } from '@splitsoftware/splitio-react';
import { config } from '../../config';
import { useServices } from '../../app/services';

type Attributes = {
  [attributeName: string]: string | number | boolean | Array<string | number>;
};

/**
 * Treatments available from split. Should be used to manage our feature flags as we integrate into split.io
 * into the new experience.
 */
export enum TREATMENTS {
  ACTIONABLE_OFFER_API_MIGRATION = 'actionable_offer_api_migration',
  ENABLE_MULTI_USER_AO_TARGETTING = 'enable_multi_user_ao_targeting',
}

export interface SplitProps {
  children?: React.ReactElement;
}

export const SplitProvider: React.FC<SplitProps> = ({ children }: SplitProps) => {
  const { tokenService } = useServices();

  const authServiceUuid = tokenService.getTokenContent()?.payload.user.uuid;

  if (children) {
    const sdkConfig = {
      core: {
        authorizationKey: config.SPLIT_IO_KEY || '',
        key: authServiceUuid || '',
      },
      features: {
        [TREATMENTS.ACTIONABLE_OFFER_API_MIGRATION]: 'on',
        [TREATMENTS.ENABLE_MULTI_USER_AO_TARGETTING]: 'on',
      },
    };

    // the updateOnSDK allows realtime changes
    // we might not actually want this...
    return (
      <SplitFactory config={sdkConfig} updateOnSdkUpdate={true}>
        <div data-testid="split-provider">{children}</div>
      </SplitFactory>
    );
  }

  return <>{children}</>;
};

/**
 * Check if a Split treatment's value is on. This helper method saves the need to import
 * from two different modules and using two lines to check for the 'on' value, while still
 * remaining type-safe.
 * @param treatment The treatment to check. Import the TREATMENTS enum and pass in the
 * value you want to check.
 */

export const useFeature = (treatment: TREATMENTS, attributes?: Attributes) => {
  const treat = useTreatments([treatment], attributes);
  const { treatment: feature } = treat[treatment];
  return feature === 'on';
};
