import { gql } from '@apollo/client';

export const GET_OFFER_DETAILS = gql`
  query actionableOfferSchedule($id: ID!) {
    actionableOfferSchedule(id: $id) {
      name
      campaignType
      campaignCode
      uuid
      srmHomeUserUuid
      srmHomeSegmentId
      occurrence
      startDate
      endDate
      timeZone
      offerType
      executionTime
      repetitionStrategy
      repetitionInterval
      executionDays
      weekOfMonth
      tags {
        tag {
          name
          id
        }
      }
    }
  }
`;
