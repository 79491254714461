import React from 'react';
import { Box, ColorManipulator, Liquidity, makeStyles, Theme } from '@c2fo/react-components';
import { Navigation } from '../../components';

interface BasePageProps {
  children: React.ReactNode;
}

const useStyles = makeStyles<Theme>(() => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: ColorManipulator.lighten(Liquidity.colors.supporting.grey, 0.85),
  },
}));

export const BasePage: React.FC<BasePageProps> = ({ children }: BasePageProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.body}>
      <Navigation />
      <Box>{children}</Box>
    </Box>
  );
};
