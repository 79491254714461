import { gql } from '@apollo/client';

const INITIATE_OFFER_VIA_CSV = gql`
  mutation runCampaignViaFile(
    $name: String!
    $file: ActionableOfferScheduleFileInput!
    $srmHomeUserUuid: String!
    $isDebugMode: Boolean!
  ) {
    runCampaignViaFile(
      campaign: { name: $name, srmHomeUserUuid: $srmHomeUserUuid, isDebugMode: $isDebugMode, file: $file }
    ) {
      status
    }
  }
`;

export { INITIATE_OFFER_VIA_CSV };
