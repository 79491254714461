import React from 'react';
import {
  Box,
  Logo,
  makeStyles,
  Theme,
  Liquidity,
  TypeBase,
  UserCircleIcon,
  Divider,
  Link,
} from '@c2fo/react-components';
import { useHistory } from 'react-router-dom';
import { useServices } from '../../services';
import { ROUTES } from '../../Router';
import { config } from '../../../config';

export const { ADMIN_APP } = config;

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    border: 'none',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    height: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  divider: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      height: 18,
    },
  },
  linkStyle: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: Liquidity.colors.primary.deepGreen,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  mail: {
    color: Liquidity.colors.primary.deepGreen,
    fontWeight: 600,
    marginBottom: theme.spacing(0.25),
    marginLeft: theme.spacing(1),
  },
}));

export const testIds = {
  USER: 'navigation-user-name',
  LOGOUT: 'navigation-logout-button',
  ACTIONABLE_OFFER: 'navigation-actionable-offer',
};

export const Navigation: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { authService, tokenService } = useServices();

  const handleRoute = () => history.push(ROUTES.ACTIONABLE_OFFER);

  const user = tokenService.getTokenContent();
  const userEmail = user?.payload.user.emailAddress ?? '';

  const handleLogout = () => {
    tokenService.clearToken();
    const redirectURL = `${ADMIN_APP}/logout?redirect-to=${encodeURIComponent(
      window.location.href,
    )}&tokenQueryParam=authorization`;
    window.location.replace(redirectURL);
  };

  return (
    <Box className={classes.header}>
      <Box className={classes.innerContainer}>
        <Link data-testid={testIds.ACTIONABLE_OFFER} className={classes.linkStyle} onClick={handleRoute}>
          <Logo className={classes.logo} />
        </Link>
      </Box>
      {authService.isAuthenticated() && (
        <Box className={classes.innerContainer}>
          <UserCircleIcon color="primary" fontSize="small" />
          <TypeBase
            align="trailing"
            data-testid={testIds.USER}
            classes={{
              root: classes.mail,
            }}
          >
            {userEmail.slice(0, userEmail.indexOf('@'))}
          </TypeBase>
          <Divider orientation="vertical" className={classes.divider} />
          <Link data-testid={testIds.LOGOUT} className={classes.linkStyle} onClick={handleLogout}>
            logout
          </Link>
        </Box>
      )}
    </Box>
  );
};
